import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#11998e",
      "50": "#dfedfe",
      "100": "#bbd1e4",
      "200": "#98b1c8",
      "300": "#7592ac",
      "400": "#5b7c98",
      "500": "#406684",
      "600": "#335874",
      "700": "#25465e",
      "800": "#173449",
      "900": "#042032",
    },
    secondary: {
      main: "#9FE6A0",
      "50": "#fdf1e2",
      "100": "#fadab8",
      "200": "#f6c28b",
      "300": "#f4aa5d",
      "400": "#f3973d",
      "500": "#ec7b28",
      "600": "#ec7b28",
      "700": "#e56d26",
      "800": "#dd5d24",
      "900": "#d34320",
    },
  },
});

// primary: {
//   "main": "#2b6f8f",
//   "50": "#e4f0f3",
//   "100": "#bbdae2",
//   "200": "#95c3d1",
//   "300": "#72abbf",
//   "400": "#5a9bb4",
//   "500": "#448caa",
//   "600": "#377fa0",
//   "700": "#2b6f8f",
//   "800": "#225f7f",
//   "900": "#154360",
// },

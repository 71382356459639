import { styled, Box } from "@mui/material";

export const Container = styled(Box)`
  display: flex;
  flex-direction: row;
  min-width: 350px;
  min-height: 250px;
`;

export const LeftContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
  background-color: #f3f3f3;
  min-width: 120px;
`;

export const ConfirmContainer = styled(Box)`
  gap: 8px;
  display: flex;
  flex-direction: column;
`;

export const RightContainer = styled(Box)`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const InputsContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

export const ChipsContent = styled(Box)`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 8px;
`;

export const ChipRowContainer = styled(Box)`
  flex: 1;
  gap: 8px;
  display: flex;
  flex-direction: column;
`;

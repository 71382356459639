import React from "react";

import { TabPanel } from "../fragments";
import { TABS } from "../constants";

export interface TabsContentProps {
  componentProps: any;
}

export const TabsContent: React.FC<TabsContentProps> = (props) => {
  const { componentProps } = props;

  return (
    <div className="flex h-full w-full flex-col rounded bg-white p-4 shadow-sm">
      {TABS.map((tab) => (
        <TabPanel key={tab.key} tab={tab.key}>
          <tab.Component {...componentProps} />
        </TabPanel>
      ))}
    </div>
  );
};

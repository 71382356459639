import React, { createContext, useState, useContext } from "react";

const initialValue: { activeTab: string | number | null } = {
  activeTab: null,
};

const initialMethods = {
  switchTab: (_tab: string | number) => {
    //
  },
};

const initialState = {
  ...initialValue,
  ...initialMethods,
};

export const TabsContext = createContext(initialState);

export const TabsProvider: React.FC<
  React.PropsWithChildren<{ fallback: string | number }>
> = (props) => {
  const { children, fallback } = props;
  const [state, setState] = useState({
    ...initialValue,
    activeTab: fallback,
  });

  const switchTab = (tab: string | number) => {
    setState({
      activeTab: tab,
    });
  };

  const value = {
    ...state,
    switchTab,
  };

  return <TabsContext.Provider value={value}>{children}</TabsContext.Provider>;
};

export const useTabs = () => useContext(TabsContext);

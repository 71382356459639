import { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import classNames from "classnames";

import { useProfile, useUpdateProfile } from "hooks/users";
import { Button } from "ui/molecules";
import { SubscriberEmailInfos } from "./SubscriberEmailInfos";

const profileForm = yup
  .object({
    firstname: yup.string().required(),
    lastname: yup.string().required(),
  })
  .required();

interface ProfileForm {
  firstname: string;
  lastname: string;
}

export const ProfilePanel = () => {
  const { mutate: updateProfile, isLoading: updateProfileLoading } =
    useUpdateProfile();
  const { control, reset, handleSubmit } = useForm<ProfileForm>({
    resolver: yupResolver(profileForm),
    defaultValues: {
      firstname: "",
      lastname: "",
    },
  });
  const { data: userProfile, isLoading } = useProfile();

  useEffect(() => {
    reset({
      firstname: userProfile?.data?.firstname ?? "",
      lastname: userProfile?.data?.lastname ?? "",
    });
  }, [userProfile?.data?.firstname, userProfile?.data?.lastname]);

  const handleSave = (formData: ProfileForm) => {
    updateProfile(formData);
  };

  return (
    <form onSubmit={handleSubmit(handleSave)}>
      <p className="text-2xl font-medium">Profile</p>

      <div className="flex flex-row gap-4">
        <div className="flex-1">
          <div className="mt-4 flex flex-col gap-2">
            <Controller
              control={control}
              name="firstname"
              render={({ field }) => (
                <input
                  value={field.value}
                  onChange={field.onChange}
                  placeholder="Name"
                  className={classNames(
                    userProfile?.data?.firstname !== field.value && !isLoading
                      ? "border-orange-500"
                      : "",
                    "max-w-[300px] rounded bg-neutral-100 px-2 py-1 text-lg border-solid border border-neutral-100 outline-none"
                  )}
                />
              )}
            />

            <Controller
              control={control}
              name="lastname"
              render={({ field }) => (
                <input
                  value={field.value}
                  onChange={field.onChange}
                  placeholder="Surname"
                  className={classNames(
                    userProfile?.data?.lastname !== field.value && !isLoading
                      ? "border-orange-500"
                      : "",
                    "max-w-[300px] rounded bg-neutral-100 px-2 py-1 text-lg outline-none border-solid border border-neutral-100"
                  )}
                />
              )}
            />

            <input
              value={userProfile?.data?.email}
              disabled
              className={classNames(
                "max-w-[300px] rounded bg-neutral-100 px-2 py-1 text-lg outline-none border-solid border border-neutral-100 opacity-70 text-gray-400"
              )}
            />
          </div>

          <div className="mt-4">
            <Button
              label="Save"
              type="submit"
              disabled={updateProfileLoading}
              loading={updateProfileLoading}
            />
          </div>
        </div>

        <SubscriberEmailInfos />
      </div>
    </form>
  );
};

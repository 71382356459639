import { useAuth } from "@ocf/react-auth";
import { useMutation, UseMutationOptions, useQueryClient } from "react-query";
import { QUERIES } from "constants/queries";

interface UseDeleteFilesOptions {
  queryOptions?: UseMutationOptions<any, any, any>;
}

export const useDeleteFiles = (options?: UseDeleteFilesOptions) => {
  const { token } = useAuth();
  const queryClient = useQueryClient();
  const mutation = useMutation(
    QUERIES.deleteFiles(queryClient, token as string, options?.queryOptions)
  );

  return mutation;
};

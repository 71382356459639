import React from "react";
import { useState } from "react";
import { Modal, CircularProgress, Button } from "@mui/material";
import { DataGrid, GridOverlay, GridSelectionModel } from "@mui/x-data-grid";

import { buildFilesRows, filesGridColumns } from "../../utils/datagrid";

import { FilesGridActionBar } from "../files-grid-action-bar/FilesGridActionBar";
import { FileVisualizer, EditModal, useFilesActions } from "..";
import { useInvoices } from "../../providers/InvoicesProvider";
import { Container } from "./styles";

// @deprecated
interface Invoice {
  id: string;
  filename: string;
  currency: string;
  date: string;
  fileStatus: string;
  receiptId: string;
  subtotal: string;
  tax: string;
  total: string;
  uploadedAt: string;
  vendorName: string;
}

const CustomNoRowsOverlay = (props: any) => {
  const { onOpenAddFiles } = props;

  return (
    <GridOverlay
      sx={{
        zIndex: 10,
        pointerEvents: "all",
      }}
    >
      <div className="mx-auto mt-6 flex w-fit flex-col justify-center gap-4 rounded border border-neutral-400 p-4">
        <p className="max-w-[300px] text-center text-lg">
          {
            "It seems there is nothing present in this area! Let's begin by selecting your invoices, click below and discover the remarkable things that will occur!"
          }
        </p>
        <Button variant="contained" onClick={onOpenAddFiles} size="small">
          Upload some magic
        </Button>
      </div>
    </GridOverlay>
  );
};

const getInvoiceById = (data: Invoice[], id: string) => {
  return data.find((i: Invoice) => i.id === id);
};

export interface FilesGridProps {
  onOpenAddFiles: () => void;
}

export const FilesGrid: React.FC<FilesGridProps> = (props) => {
  const { onOpenAddFiles } = props;
  // TODO: add useFilesAction select
  const [selectedToDownload, setSelectedToDownload] = useState<
    { id: string; filename: string }[]
  >([]);
  const [selecting, setSelecting] = useState(false);
  const [visualizerOpen, setVisualizerOpen] = useState(false);
  const [selectedToEdit, setSelectedToEdit] = useState<any>(null);
  const [pageSize, setPageSize] = useState(10);
  // const { data, isLoading } = useGetInvoices();
  // eslint-disable-next-line
	// @ts-ignore
  const { data, isLoading } = useInvoices();
  const { select, selected } = useFilesActions();

  const selectPageSize = (pageSize: number) => {
    setPageSize(pageSize);
  };

  const editSelected = () => {
    setSelectedToEdit(selectedToDownload[0]);
  };

  const closeVisualizer = () => {
    setSelectedToDownload([]);
    setVisualizerOpen(false);
  };

  const onSelect = (newModel: GridSelectionModel) => {
    const newValues = newModel.map((i) => i.toString());
    const files =
      data?.data?.invoices?.filter((i) => newModel.includes(i.id)) ?? [];
    setSelectedToDownload(
      files.map((i) => ({ id: i.id, filename: i.filename }))
    );
    const selectedId = selected.map((item) => item.id);
    const newItems = newValues.filter((i: string) => !selectedId.includes(i));

    if (newItems) {
      newItems.forEach((item) => {
        select(item, null);
      });
    }
  };

  const selectedInvoice = selectedToEdit
    ? getInvoiceById(data?.data?.invoices ?? [], selectedToEdit?.id)
    : null;

  const invoicesRows = buildFilesRows(data?.data?.invoices ?? []);

  return (
    <Container>
      {visualizerOpen ? (
        <Modal open={visualizerOpen} onClose={closeVisualizer}>
          <FileVisualizer
            fileId={selectedToDownload[0]?.id}
            onClose={closeVisualizer}
          />
        </Modal>
      ) : null}

      <FilesGridActionBar
        selectedToDownload={selectedToDownload}
        setSelectedToDownload={setSelectedToDownload}
        selecting={selecting}
        setSelecting={setSelecting}
        setSelectedToEdit={editSelected}
        setVisualizerOpen={() => setVisualizerOpen(true)}
        onOpenAddFiles={onOpenAddFiles}
      />

      {selectedToEdit && selectedInvoice && (
        <EditModal
          invoice={selectedInvoice}
          open={!!selectedToEdit}
          onClose={() => setSelectedToEdit(null)}
        />
      )}

      {isLoading ? (
        <CircularProgress />
      ) : (
        <div id="grid" className="flex flex-1 bg-white">
          <DataGrid
            rows={invoicesRows}
            columns={filesGridColumns}
            checkboxSelection={selecting}
            onSelectionModelChange={onSelect}
            components={{
              NoRowsOverlay: () => (
                <CustomNoRowsOverlay onOpenAddFiles={onOpenAddFiles} />
              ),
            }}
            selectionModel={selectedToDownload.map((i) => i.id)}
            rowsPerPageOptions={[5, 10, 25]}
            pagination
            pageSize={pageSize}
            onPageSizeChange={selectPageSize}
          />
        </div>
      )}
    </Container>
  );
};

import type React from "react";
import { useEffect } from "react";

export interface UseClickOutsideParams<T> {
  ref: React.MutableRefObject<T>;
  action: () => void;
}

// TODO: put in folder hooks/utilities
export const useClickOutside = <T>(params: UseClickOutsideParams<T>) => {
  const { ref, action } = params;

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // eslint-disable-next-line
			// @ts-ignore
      if (ref.current && !ref.current?.contains(event.target)) {
        action();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, action]);
};

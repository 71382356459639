import { Tab } from "../fragments";

import { TABS } from "../constants";

export const ProfileTabs = () => {
  return (
    <div className="flex w-full flex-row items-center rounded bg-white p-2 shadow-sm">
      {TABS.map((tab) => (
        <Tab key={tab.key} label={tab.label} tab={tab.key} url={tab.url} />
      ))}
    </div>
  );
};

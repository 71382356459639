import React, { useState, useRef, useEffect } from "react";
import moment from "moment";
import { Bars } from "react-loader-spinner";

import {
  useCancelSubscription,
  useProfile,
  useResumeSubscription,
  useSubscriptionDetails,
} from "hooks/users";
import { useClickOutside } from "hooks/utilities";

import { ButtonBase } from "ui/atoms";
import { useNavigation } from "hooks/useNavigation";
import { Infos } from "./Infos";
import { TransactionsHistory } from "../../subscription-panel/TransactionHistory";

export const SubscriptionDetails: React.FC = () => {
  const { data: userProfile, isLoading: profileLoading } = useProfile();
  const ref = useRef(true);
  const { data: subscription, isLoading: subscriptionLoading } =
    useSubscriptionDetails({
      subscriptionId: userProfile?.data?.stripeSubscriptionId,
    });

  const [confirmCancelModal, setConfirmCancelModal] = useState(false);
  const { subscriptions } = useNavigation();
  const [resumeSubscriptionModal, setResumeSubscriptionModal] = useState(false);
  const { mutate: resumeSubscription, isLoading: resumeSubLoading } =
    useResumeSubscription({
      queryOptions: {
        onSuccess: () => {
          setResumeSubscriptionModal(false);
        },
      },
    });
  const { mutate: cancelSubscription } = useCancelSubscription();
  const confirmModal = useRef(null);
  const resumeModal = useRef(null);
  useClickOutside({
    action: () => {
      setConfirmCancelModal(false);
    },
    ref: confirmModal,
  });
  useClickOutside({
    action: () => {
      setResumeSubscriptionModal(false);
    },
    ref: resumeModal,
  });

  useEffect(() => {
    if (!profileLoading && !subscriptionLoading && ref.current !== true) {
      if (!subscription?.data && !userProfile?.data?.stripeSubscriptionId) {
        subscriptions.goToPlans();
      }

      if (subscription?.data?.status === "incomplete") {
        if (subscription?.data?.canceled) {
          subscriptions.goToPlans();
        } else {
          subscriptions.goToPayement(subscription.data.subscriptionId);
        }
      }
    }

    if (!subscriptionLoading && !profileLoading) {
      if (
        !subscription?.data &&
        !subscriptionLoading &&
        !userProfile?.data?.stripeSubscriptionId
      ) {
        subscriptions.goToPlans();
      }
    }

    ref.current = false;
  }, [subscription?.data, subscriptionLoading, profileLoading]);

  const openConfirmCancelModal = () => {
    setConfirmCancelModal(true);
  };

  const openResumeSubscriptionModal = () => {
    setResumeSubscriptionModal(true);
  };

  const handleCancelSubscription = () => {
    cancelSubscription();
    setConfirmCancelModal(false);
  };

  if (subscriptionLoading || profileLoading) {
    return (
      <div className="flex h-full w-full flex-col items-center justify-center">
        <Bars width={32} height={32} color="#11998e" />
      </div>
    );
  }

  if (
    !subscription?.data &&
    !subscriptionLoading &&
    !userProfile?.data?.stripeSubscriptionId
  ) {
    return <></>;
  }

  const amount = +((subscription?.data?.amountDue ?? 1) / 100)?.toFixed(2);

  return (
    <>
      <div className="flex h-full w-full flex-row gap-8">
        <Infos
          onCancelSubscription={openConfirmCancelModal}
          onResumeSubscription={openResumeSubscriptionModal}
          label={subscription.data.label}
          canceled={!!subscription.data.canceled}
          canceledOn={subscription.data.currentPeriodEnd}
          amount={amount}
          interval={subscription.data.plan.interval}
          status={subscription.data.status}
          loading={resumeSubLoading}
        />

        <div className="flex h-full w-1/3 flex-col">
          <TransactionsHistory />
        </div>
      </div>

      {confirmCancelModal && (
        <>
          <div className="fixed inset-0 z-10 bg-[#212121]/40" />
          <div
            className="fixed left-1/2 top-1/2 z-30 translate-x-[-50%] translate-y-[-50%] rounded bg-white p-4"
            ref={confirmModal}
          >
            <div className="flex flex-col gap-4">
              <p className="text-xl">
                Are you sure you want to cancel your subscription?
              </p>
              <p>
                Your subscription will end now but you will still have access to
                the app until the current period
              </p>
              <p className="font-semibold">
                You will lose access on{" "}
                {moment(subscription?.data?.currentPeriodEnd * 1000).format(
                  "dddd, DD MMMM YYYY"
                )}{" "}
                <span className="text-sm">
                  (
                  {moment(subscription?.data?.currentPeriodEnd * 1000).diff(
                    moment(),
                    "days"
                  )}{" "}
                  days remaining)
                </span>
              </p>

              <ButtonBase onClick={handleCancelSubscription}>
                <p className="w-fit rounded bg-primary px-2 py-1 text-white hover:opacity-90">
                  Yes, cancel my plan
                </p>
              </ButtonBase>
            </div>
          </div>
        </>
      )}

      {resumeSubscriptionModal && (
        <>
          <div className="fixed inset-0 z-10 bg-[#212121]/40" />
          <div
            className="fixed left-1/2 top-1/2 z-30 translate-x-[-50%] translate-y-[-50%] rounded bg-white p-4"
            ref={resumeModal}
          >
            <div className="flex flex-col gap-4">
              <p className="text-xl">
                Are you sure you want to resume your subscription?
              </p>
              <p>
                You will be charge{" "}
                <span className="font-semibold">{amount} €</span>, if your
                current period is not finished yet you will resume it and be
                charged at the end of the period
              </p>

              <ButtonBase onClick={() => resumeSubscription()}>
                <p className="w-fit rounded bg-primary px-2 py-1 text-white hover:opacity-90">
                  Yes, resume my subscription
                </p>
              </ButtonBase>
            </div>
          </div>
        </>
      )}
    </>
  );
};

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "@mui/material";
import { useAuth } from "@ocf/react-auth";
import { useAuth as useCustomAuth } from "hooks/users/useAuth";
import { useNotifications } from "hooks/useNotifications";
import GoogleButton from "react-google-button";

import { useNavigation } from "hooks/useNavigation";
import { AuthLayout } from "ui/layouts/auth-form/AuthForm";
import { buildField } from "../buildField";

import { defaultValues, signinSchema, SigninForm } from "./utils";
import { Form } from "./styles";
import { useEffect } from "react";
import { Divider } from "ui/atoms";

export const Signin = () => {
  const { goToRegister, goToForgotPassword, goToHome } = useNavigation();
  const { loginWithEmail, overrideToken, overrideRefreshToken } = useAuth();
  const { loginWithGoogleSSO, getAuthUser } = useCustomAuth();
  const { pushNotification } = useNotifications();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<SigninForm>({
    resolver: yupResolver(signinSchema),
    defaultValues,
  });

  useEffect(() => {
    if (typeof window !== "undefined") {
      const params = new URL(window.location.href).searchParams;
      const ggsso = params.get("ggsso") === "signin";

      if (ggsso) {
        getAuthUser(({ err, data }: any) => {
          if (err) {
            console.log({ err });
          } else {
            const token = data?.signInUserSession?.accessToken?.jwtToken;
            const refreshToken =
              data?.signInUserSession?.refreshToken?.jwtToken;

            if (token) {
              overrideToken(token);
            }

            if (refreshToken) {
              overrideRefreshToken(refreshToken);
            }

            goToHome();
          }
        });
      }
    }
  }, []);

  const fields = [
    buildField("email", "Email", errors?.email?.message?.toString()),
    buildField("password", "Password", errors?.password?.message?.toString(), {
      type: "password",
    }),
  ];

  const onSubmit = async (data: SigninForm) => {
    const { email, password } = data;
    const options = {
      onFailure: () => {
        pushNotification(
          "Wrong credentials, please check you email or password",
          "error"
        );
      },
      onSuccess: () => {
        goToHome();
      },
    };
    await loginWithEmail(email, password, options);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <AuthLayout
        control={control}
        fields={fields}
        title="Invoices Magic Bucket"
        subtitle="All invoices, One place."
        sectionTitle="Sign In"
        renderButtons={() => (
          <>
            <div onClick={goToForgotPassword}>
              <p className="w-fit text-sm text-[#335874] underline hover:text-[#5b7c98]">
                Forgot your password?
              </p>
            </div>
            <Button variant="contained" type="submit">
              Sign in
            </Button>

            <Divider label="or" />

            <div className="flex w-full flex-col">
              <GoogleButton
                onClick={loginWithGoogleSSO}
                type="light"
                style={{ width: "100%", borderRadius: 4 }}
              />
            </div>

            <div className="mt-4 flex flex-row items-center justify-center gap-2">
              <p className="text-sm text-neutral-400">
                <span>{"Doesn't have an account yet? "}</span>
                <button
                  onClick={goToRegister}
                  className="border-none bg-transparent text-[#11998e] underline"
                >
                  Register
                </button>
              </p>
            </div>
          </>
        )}
      />
    </Form>
  );
};

import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useNavigation } from "hooks/useNavigation";
import { useCancelSubscription, useSubscriptionDetails } from "hooks/users";
import { useEffect, useState } from "react";
import { Bars } from "react-loader-spinner";
import { Button } from "ui/molecules";

const stripe = loadStripe(process.env.REACT_APP_STRIPE_API_KEY ?? "");

const SubscriptionSummary = (props: any) => {
  const {
    label,
    amount,
    interval,
    discount,
    amountDue,
    duration,
    durationMonths,
  } = props;

  return (
    <div className="rounded border p-4">
      <p className="text-2xl">{label}</p>
      <p className="mt-2">
        {discount ? (
          <p className="text-base text-neutral-500 line-through">{amount} €</p>
        ) : (
          <></>
        )}
        <span className="bg-gradient-to-tr from-[#73D1AC] to-[#4DABCA] bg-clip-text text-xl font-semibold text-transparent">
          {amountDue}
        </span>
        € / {interval}
      </p>

      {duration && (
        <p className="text-sm text-neutral-500">
          {duration === "once"
            ? "Once, then ${amount} €"
            : duration === "repeating"
            ? `For ${durationMonths} months then ${amount}€`
            : ""}
        </p>
      )}
    </div>
  );
};

// eslint-disable-next-line
const withStripeElement = (Component: any) => (props: any) => {
  const { queries } = useNavigation();
  const sub = queries.get("sub") ?? "";
  const { subscriptions } = useNavigation();
  const { data: subDetails, isLoading } = useSubscriptionDetails({
    subscriptionId: sub,
  });
  const clientSecret = subDetails?.data?.clientSecret;

  useEffect(() => {
    if ((!clientSecret && !isLoading) || !sub) {
      subscriptions.goToDetails();
    }
  }, [clientSecret]);

  if (!clientSecret) {
    return (
      <div className="flex flex-row justify-center pt-12">
        <Bars width={24} height={24} color="#11998e" />
      </div>
    );
  }

  return (
    <Elements
      stripe={stripe}
      options={{
        clientSecret,
      }}
    >
      <Component {...props} />
    </Elements>
  );
};

export const SubscriptionPayment = withStripeElement(() => {
  const { queries } = useNavigation();
  const stripe = useStripe();
  const elements = useElements();
  const sub = queries.get("sub");
  const [paymentLoading, setPaymentLoading] = useState(false);
  const { data: subscription } = useSubscriptionDetails({
    subscriptionId: sub ?? "",
  });
  const { mutateAsync: cancelSubscription } = useCancelSubscription();
  const { subscriptions } = useNavigation();

  const handleSubmit = async (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    setPaymentLoading(true);

    if (!stripe || !elements) {
      return null;
    }

    // eslint-disable-next-line
		// @ts-ignore
    await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${process.env.REACT_APP_BASE_URL}/subscription/success`,
      },
    });
  };

  const onCancel = async (ev: any) => {
    ev.preventDefault();
    await cancelSubscription();
    subscriptions.goToDetails();
  };

  const amount = ((subscription?.data?.plan?.amount ?? 1) / 100).toFixed(2);

  return (
    <form
      className="flex flex-1 flex-row items-start gap-4"
      onSubmit={handleSubmit}
    >
      <div className="flex flex-1 flex-col gap-4">
        <SubscriptionSummary
          label={subscription?.data?.plan?.label}
          discount={subscription?.data?.discount?.amount}
          discountKind={subscription?.data?.discount?.kind}
          amountDue={((subscription?.data?.amountDue ?? 1) / 100).toFixed(2)}
          duration={subscription?.data?.discount?.duration}
          durationMonths={subscription?.data?.discount?.durationInMonths}
          amount={amount}
          interval={
            subscription?.data?.plan?.interval === "month" ? "Month" : "Year"
          }
        />

        <div className="flex flex-row items-center gap-2">
          <Button label="Cancel" onClick={onCancel} />
          <Button
            label="Confirm Payment"
            type="submit"
            loading={paymentLoading}
          />
        </div>
      </div>

      <div className="flex-1">
        <PaymentElement
          options={{
            layout: "accordion",
          }}
        />
      </div>
    </form>
  );
});

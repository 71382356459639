import { styled, Paper } from "@mui/material";

export const Container = styled("div")`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  background-image: radial-gradient(
    circle farthest-corner at 10% 20%,
    rgba(176, 229, 208, 1) 42%,
    rgba(92, 202, 238, 0.41) 93.6%
  );
`;

export const Content = styled(Paper)`
  flex-direction: column;
  display: flex;
  width: 60%;
  padding: 1rem;
  gap: 1rem;
  max-width: 500px;
`;

import React from "react";

import { usePaymentsHistory } from "hooks/users";

import { InvoiceCard } from "./InvoiceCard";
import { Bars } from "react-loader-spinner";

export const TransactionsHistory: React.FC = () => {
  const { data: paymentHistory, isLoading } = usePaymentsHistory();

  return (
    <div className="w-full p-4">
      <p className="text-xl font-semibold">Transaction History</p>

      {isLoading && (
        <div className="mt-8 flex flex-row justify-center">
          <Bars width={24} height={24} color="#11998e" />
        </div>
      )}

      <div className="mt-4 flex flex-col gap-2">
        {paymentHistory?.data?.map((invoice: any) => (
          <InvoiceCard invoice={invoice} key={invoice.id} />
        ))}
      </div>
    </div>
  );
};

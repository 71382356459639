import { useNavigation } from "hooks/useNavigation";
import { Button } from "ui/molecules";

export const SubscriptionSuccess = () => {
  const { goToHome } = useNavigation();

  return (
    <div className="flex flex-col items-center gap-4 pt-12">
      <p className="text-2xl">Your subscription is active!</p>

      <p className="max-w-[500px] text-center">
        {
          "You can now use all of IMB's features, check your profile to see which email has been assigned to you. Use this email to send your invoices to, after being processed you will see them in your dashboard. If you don't want to use email, you can upload them right in the dashboard. Enjoy!"
        }
      </p>

      <Button label="Start uploading invoices" onClick={goToHome} />
    </div>
  );
};

import React, { useState, useRef } from "react";
import classNames from "classnames";
import { useAuth } from "@ocf/react-auth";

import { useProfile, useRole } from "hooks/users";
import { useNavigation } from "hooks/useNavigation";
import { useClickOutside } from "hooks/utilities";

import { ButtonBase } from "ui/atoms";

import { Container } from "./styled";
import { useQueryClient } from "react-query";

const getInitials = (firstname: string, lastname: string) => {
  if (!firstname && !lastname) {
    return "UN";
  }

  return `${firstname?.toUpperCase()?.charAt(0) ?? ""}${
    lastname?.toUpperCase()?.charAt(0) ?? ""
  }`;
};

const AvatarMenu = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const { data: profile } = useProfile();
  const { hasAccess, role } = useRole();
  const { goToProfile, goToSignin } = useNavigation();
  const queryClient = useQueryClient();
  const { logout } = useAuth();
  useClickOutside({
    ref: menuRef,
    action: () => setMenuOpen(false),
  });

  const handleGoToProfile = () => {
    setMenuOpen(false);
    goToProfile();
  };

  const handleLogout = () => {
    setMenuOpen(false);
    queryClient.removeQueries();
    logout();
    goToSignin();
  };

  return (
    <div className="relative">
      <ButtonBase onClick={() => setMenuOpen(true)}>
        <div className="flex h-8 w-8 items-center justify-center rounded-full border border-solid border-[#212121] bg-neutral-100 hover:bg-neutral-200">
          <p className="text-sm">
            {getInitials(profile?.data?.firstname, profile?.data?.lastname)}
          </p>
        </div>
      </ButtonBase>

      {menuOpen && (
        <div
          className="absolute right-0 top-full z-20 min-w-[250px] rounded-md bg-white p-2 shadow-xl"
          ref={menuRef}
        >
          <div className="mt-2 flex justify-center">
            <p
              className={classNames(
                "w-fit rounded-full bg-primary px-3 py-1 text-xs text-white"
              )}
            >
              {hasAccess(role) ? role : "FREE"}
            </p>
          </div>

          <div className="mt-2 flex flex-col gap-2">
            <ButtonBase onClick={handleGoToProfile}>
              <p className="rounded px-2 py-1.5 hover:bg-[#efefef]">Profile</p>
            </ButtonBase>

            <ButtonBase onClick={handleLogout}>
              <p className="rounded px-2 py-1.5 text-red-600 hover:bg-[#efefef]">
                Logout
              </p>
            </ButtonBase>

            <p className="text-center text-xs text-gray-400">
              v{process.env.REACT_APP_APP_VERSION}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

const AppBar = () => {
  const { goToHome } = useNavigation();

  return (
    <header className="z-10 flex flex-row items-center justify-between p-4 shadow">
      <div />

      <div className="flex flex-row items-center gap-4">
        <ButtonBase onClick={goToHome}>
          <p>Dashboard</p>
        </ButtonBase>

        <div id="menu-profile-btn">
          <AvatarMenu />
        </div>
      </div>
    </header>
  );
};

export const AppLayout: React.FC<React.PropsWithChildren> = (props) => {
  const { children } = props;

  return (
    <Container>
      <AppBar />

      <main className="flex grow bg-neutral-100">{children}</main>
    </Container>
  );
};

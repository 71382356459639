import { Box, Paper, Button as MUIButton, styled } from "@mui/material";

export const MainContainer = styled(Box)`
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	display: flex;
	flex-direction: row;
	gap 2rem;
`;

export const Container = styled(Paper)`
  height: 100%;
  width: 500px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
`;

export const FormContainer = styled("form")`
  flex-grow: 1;
  overflow: auto;
  padding-top: 8px;
  padding-bottom: 8px;
`;

export const ButtonsContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

export const Button = styled(MUIButton)`
  flex-grow: 1;
`;

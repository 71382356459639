import * as yup from "yup";
import { emailSchema, passwordSchema } from "utils/form-schemas";

export interface SigninForm {
  email: string;
  password: string;
}

export const signinSchema = yup.object({
  email: emailSchema,
  password: passwordSchema,
});

export const defaultValues: SigninForm = {
  email: "",
  password: "",
};

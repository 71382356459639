import * as yup from "yup";

export const confirmAccountSchema = yup
  .object({
    code: yup.string().min(6).max(6).required(),
  })
  .required();

export interface ConfirmAccountForm {
  code: string;
}

export const defaultValues: ConfirmAccountForm = {
  code: "",
};

export const errorCodeToString = (code: string) => {
  switch (code) {
    case "100#03":
      return "The code has expired, please ask for a new code";
    case "100#02":
      return "The code is invalid, please enter the code received by email";
    default:
      return "Wrong code, please verify that the code is valid";
  }
};

import { styled, Box } from "@mui/material";

export const Container = styled(Box)`
  background-color: white;
  padding-top: 16px;
  padding-bottom: 16px;
  border-radius: 4px;
  width: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media only screen and (min-width: 768px) {
    & {
      width: 60%;
    }
  }

  @media only screen and (min-width: 1200px) {
    & {
      width: 40%;
    }
  }
`;

interface DropAreaContainerProps {
  $active: boolean;
}

export const DropAreaContainer = styled(Box)<DropAreaContainerProps>`
  background-color: #efefef;
  border: ${(props) =>
    props.$active
      ? `2px dashed ${props.theme.palette.secondary.main}`
      : "1px dashed grey"};
  margin-top: 16px;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: grey;
  padding-top: 16px;
  padding-bottom: 16px;
  position: relative;
`;

export const DropInput = styled("input")`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
`;

export const ButtonsContainer = styled(Box)`
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
  gap: 8px;
`;

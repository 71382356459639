import axios, { AxiosProgressEvent } from "axios";

import { handlePromise } from "utils/promises";
import { API_URLS } from "constants/api";

export const getUploadFileUrl = async (token: string, file: File) => {
  const data = {
    filename: file.name,
    contentType: file.type,
  };
  const config = {
    headers: {
      Authorization: token,
    },
  };
  const { err, data: responseData } = await handlePromise(
    axios.post(API_URLS.uploadFile(), data, config)
  );

  return { err, data: responseData?.data };
};

export const uploadFileToSignedUrl = async (
  signedUrl: string,
  file: File,
  onProgress?: (progressData: { progress: number }) => void
) => {
  const config = {
    headers: {
      "Content-Type": "application/pdf",
      "x-amz-server-side-encryption": "aws:kms",
      "x-amz-server-side-encryption-aws-kms-key-id":
        process.env.REACT_APP_KMS_KEY_ID,
    },
    onUploadProgress: (progressEvent: AxiosProgressEvent) => {
      if (progressEvent && onProgress) {
        const percentage = (progressEvent.progress ?? 0) * 100;
        onProgress({
          progress: percentage,
        });
      }
    },
  };
  const { err, data } = await handlePromise(axios.put(signedUrl, file, config));
  return { err, data: data?.data };
};

export const uploadFile = async (
  token: string,
  file: File,
  onProgress: any
) => {
  const { data: getSignedUrlData, err: getSignedUrlErr } =
    await getUploadFileUrl(token, file);

  if (getSignedUrlErr) {
    throw new Error("Internal Server Error");
  }

  const { err: uploadErr } = await uploadFileToSignedUrl(
    getSignedUrlData.signedUrl,
    file,
    onProgress
  );

  if (uploadErr) {
    throw new Error("Internal Server Error");
  }

  return {
    fileId: getSignedUrlData.fileId,
  };
};

export const deleteFiles = async (token: string, fileIds: string[]) => {
  const params = new URLSearchParams();

  fileIds.forEach((f) => {
    params.append("fileIds", f);
  });

  const apiUrl = API_URLS.deleteFiles();
  await axios.delete(apiUrl, {
    params: params,
    headers: {
      Authorization: token,
    },
  });
};

export const getInvoiceUrl = async (token: string, fileId: string) => {
  const config = {
    headers: {
      Authorization: token,
    },
  };

  const { err, data } = await new Promise<{ data?: any; err?: any }>(
    (resolve) => {
      axios
        .get(API_URLS.getInvoiceUrl(fileId), config)
        .then((result) => resolve({ err: null, data: result }))
        .catch((err) => resolve({ err, data: null }));
    }
  );

  if (err) {
    throw new Error("Internal Server Error");
  }

  return data?.data?.url;
};

export const updateInvoiceData = async (token: string, invoiceData: any) => {
  const config = {
    headers: {
      Authorization: token,
    },
  };

  const { err, data } = await new Promise<any>((resolve) => {
    axios
      .patch(API_URLS.invoiceUpdate(invoiceData.id), invoiceData, config)
      .then((result) => resolve({ err: null, data: result.data }))
      .catch((err) => resolve({ err, data: null }));
  });

  if (err) {
    throw new Error("Internal Server Error");
  }

  return data;
};

interface DownloadInvoicesData {
  invoiceIds: string[];
  type: "PDF" | "CSV";
}

export const downloadInvoices = async (
  token: string,
  invoicesData: DownloadInvoicesData
) => {
  const config = {
    headers: {
      Authorization: token,
    },
  };

  const params = {
    fileIds: invoicesData.invoiceIds,
  };

  const { err, data } = await new Promise<any>((resolve) => {
    axios
      .post(API_URLS.downloadFiles(invoicesData.type), params, config)
      .then((response) => resolve({ err: null, data: response }))
      .catch((err) => resolve({ err, data: null }));
  });

  if (err) {
    throw new Error("Internal Server Error");
  }

  return data.data;
};

import { useQuery, UseQueryOptions } from "react-query";

import { useAuth } from "@ocf/react-auth";

import { getPaymentHistory } from "api/user";

export interface UseGetInvoicesOptions {
  queryOptions?: UseQueryOptions<any>;
}

export const usePaymentsHistory = (options?: UseGetInvoicesOptions) => {
  const { token } = useAuth();

  const queryOptions: UseQueryOptions<any> = {
    queryKey: ["users", "payments"],
    queryFn: () => getPaymentHistory(token ?? ""),
    enabled: !!token,
    ...options?.queryOptions,
  };
  return useQuery(queryOptions);
};

import { styled, Box } from "@mui/material";

export const Container = styled(Box)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0.5rem;
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
  z-index: 999;
`;

import { useMutation, UseMutationOptions } from "react-query";
import { useAuth } from "@ocf/react-auth";
import { createSubscriptionIntent } from "api/user";

export interface UseCreateSubscriptionOptions {
  queryOptions?: UseMutationOptions<any, null, UseCreateSubscriptionForm>;
}

export interface UseCreateSubscriptionForm {
  customerId: string;
  priceId: string;
  coupon: null | string;
}

export const useCreateSubscription = (
  options?: UseCreateSubscriptionOptions
) => {
  const { token } = useAuth();

  const queryOptions: UseMutationOptions<any, null, UseCreateSubscriptionForm> =
    {
      mutationKey: ["users", "subscription"],
      mutationFn: (formData) =>
        createSubscriptionIntent(
          token ?? "",
          formData.customerId,
          formData.priceId,
          formData.coupon
        ),
      ...options?.queryOptions,
    };

  return useMutation<any, null, UseCreateSubscriptionForm>(queryOptions);
};

import { Navigation } from "./navigation";
import { Providers } from "providers";

export const App = () => {
  return (
    <Providers>
      <Navigation />
    </Providers>
  );
};

import { Chip, ChipProps } from "@mui/material";
import { Done, Loop, Error } from "@mui/icons-material";
import * as INVOICES from "constants/invoices";
import { Invoice } from "types/invoice";

const getInvoiceCategoryLabel = (key?: string) => {
  const item = INVOICES.CATEGORIES.find((category) => category.key === key);

  return item?.label ?? "To Categorize";
};

const timestampToString = (timestamp: number): string => {
  const date = new Date(+timestamp);
  return date.toDateString();
};

export const buildFilesRows = (files: Invoice[]) => {
  return files.map((file: Invoice) => ({
    id: file.id,
    filename: file.filename,
    status: file.fileStatus,
    date: file.date,
    currency: file.currency,
    receiptId: file.receiptId,
    category: getInvoiceCategoryLabel(file?.category),
    vendorName: file.vendorName,
    subtotal: Number(file.subtotal).toFixed(2),
    tax: Number(file.tax).toFixed(2),
    total: Number(file.total).toFixed(2),
  }));
};

interface Status {
  label: ChipProps["label"];
  icon: ChipProps["icon"];
  color: ChipProps["color"];
}

const formatStatus = (rawStatus: string): Status => {
  switch (rawStatus) {
    case "COMPLETE":
      return {
        label: "Complete",
        icon: <Done fontSize="small" />,
        color: "success",
      };
    case "PRE_UPLOAD":
      return {
        label: "Uploading",
        icon: (
          <div className="animation-reverse flex h-full animate-spin flex-col items-center justify-center">
            <Loop fontSize="small" />
          </div>
        ),
        color: "warning",
      };
    case "ANALYZING":
      return {
        label: "Analyzing",
        icon: (
          <div className="animation-reverse flex h-full animate-spin flex-col items-center justify-center">
            <Loop fontSize="small" />
          </div>
        ),
        color: "warning",
      };
    case "ERROR":
      return { label: "Error", icon: <Error />, color: "error" };
    default:
      return { label: "Error", icon: <Error />, color: "error" };
  }
};

const formatCurrency = (amount: number, currency: string) => {
  switch (currency) {
    case "EUR":
      return `${amount} €`;
    case "USD":
      return `$ ${amount}`;
    case "GBP":
      return `£ ${amount}`;
    default:
      return amount;
  }
};

export const filesGridColumns = [
  {
    field: "filename",
    headerName: "Filename",
    width: 200,
  },
  {
    field: "status",
    headerName: "Upload Status",
    width: 150,
    renderCell: (data: any) => {
      const { label, icon, color } = formatStatus(data.row.status);
      return (
        <Chip
          label={label}
          color={color}
          icon={icon}
          size="small"
          variant="outlined"
        />
      );
    },
  },
  {
    field: "date",
    headerName: "Billing Date",
    valueGetter: (params: any) => timestampToString(params.row.date),
    width: 180,
  },
  {
    field: "category",
    headerName: "Category",
    width: 150,
    renderCell: (data: any) => {
      return <Chip label={data.row.category} size="small" variant="outlined" />;
    },
  },
  {
    field: "receiptId",
    headerName: "Receipt Number",
    width: 200,
  },
  {
    field: "vendorName",
    headerName: "Vendor Name",
    width: 200,
  },
  {
    field: "subtotal",
    headerName: "Subtotal",
    valueGetter: (params: any) =>
      formatCurrency(params.row.subtotal, params.row.currency),
  },
  {
    field: "tax",
    headerName: "Tax",
    valueGetter: (params: any) =>
      formatCurrency(params.row.tax, params.row.currency),
  },
  {
    field: "total",
    headerName: "Total",
    valueGetter: (params: any) =>
      formatCurrency(params.row.total, params.row.currency),
  },
];

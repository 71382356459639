import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Divider } from "@mui/material";
import { useAuth } from "@ocf/react-auth";

import { useNavigation } from "hooks/useNavigation";
import { AuthLayout } from "ui/layouts/auth-form/AuthForm";
import { useNotifications } from "hooks/useNotifications";

import { Form } from "../signin/styles";
import { buildField } from "../buildField";

import {
  defaultValues,
  ResetPasswordForm,
  resetPassswordSchema,
} from "./utils";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

export const ResetPassword = () => {
  const { goToSignin } = useNavigation();
  const { state } = useLocation();
  const { resetPassword } = useAuth();
  const { pushNotification } = useNotifications();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ResetPasswordForm>({
    resolver: yupResolver(resetPassswordSchema),
    defaultValues,
  });

  useEffect(() => {
    if (!state?.email) {
      goToSignin();
    }
  }, [state]);

  const fields = [
    buildField("password", "Password", errors?.password?.message?.toString(), {
      type: "password",
    }),
    buildField(
      "confirmPassword",
      "Confirm Password",
      errors?.confirmPassword?.message?.toString(),
      { type: "password" }
    ),
    buildField("code", "Reset Code", errors?.code?.message?.toString()),
  ];

  const onSubmit = async (data: ResetPasswordForm) => {
    await resetPassword(
      state.email,
      data.password,
      {
        code: data.code,
      },
      {
        onSuccess: () => {
          pushNotification("Your password has been updated", "success");
          goToSignin();
        },
        onFailure: () => {
          pushNotification(
            "An error occured while trying to reset password",
            "error"
          );
        },
      }
    );
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <AuthLayout
        control={control}
        fields={fields}
        title="Invoices Magic Bucket"
        subtitle="All invoices, One place."
        sectionTitle="New Password"
        renderButtons={() => (
          <>
            <Button variant="contained" type="submit">
              Change Password
            </Button>
            <Divider />
            <Button variant="text" onClick={goToSignin}>
              Go back to SignIn
            </Button>
          </>
        )}
      />
    </Form>
  );
};

import { useQuery, UseQueryOptions } from "react-query";
import { useAuth } from "@ocf/react-auth";

import * as UserService from "api/user";

export interface UseSubscriptionCouponOptions {
  queryOptions?: UseQueryOptions<any, any, any>;
  coupon: string | null;
}

export const useSubscriptionCoupon = (
  options: UseSubscriptionCouponOptions
) => {
  const { token } = useAuth();

  const queryConfig: UseQueryOptions<any, any, any> = {
    ...options?.queryOptions,
    enabled: !!options.coupon,
    queryKey: ["users", "coupons", { coupon: options?.coupon }],
    queryFn: async () => {
      if (!token || !options?.coupon) {
        return false;
      }

      return UserService.checkCouponValidity({
        token,
        formData: {
          coupon: options?.coupon,
        },
      });
    },
  };

  return useQuery<any, any, any>(queryConfig);
};

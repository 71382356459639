import { useQuery, UseQueryOptions } from "react-query";
import { useAuth } from "@ocf/react-auth";
import { getSubscriptionsPlans } from "api/user";

export interface UseSubscriptionsPlansOptions {
  queryOptions?: UseQueryOptions<any>;
}

export const useSubscriptionsPlans = (
  options?: UseSubscriptionsPlansOptions
) => {
  const { token } = useAuth();

  const queryOptions: UseQueryOptions<any> = {
    queryKey: ["subscriptions"],
    queryFn: () => getSubscriptionsPlans(token ?? ""),
    enabled: !!token,
    ...options?.queryOptions,
  };

  return useQuery(queryOptions);
};

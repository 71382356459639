import { useAuth } from "@ocf/react-auth";
import { useMutation, useQueryClient } from "react-query";
import { QUERIES } from "constants/queries";

export const useDownloadInvoices = () => {
  const { token } = useAuth();
  const client = useQueryClient();
  const mutation = useMutation(
    QUERIES.downloadInvoices(client, token as string)
  );

  return mutation;
};

import React from "react";
import moment from "moment";

import { stripeStatusToText } from "./utils";

export interface InvoiceCardProps {
  invoice: any;
}

export const InvoiceCard: React.FC<InvoiceCardProps> = (props: any) => {
  const { invoice } = props;

  return (
    <div className="rounded bg-neutral-100 p-2">
      <p className="font-medium text-neutral-600">
        {moment(invoice.periodStart * 1000).format("YY.MM.DD")} -{" "}
        {moment(invoice.periodEnd * 1000).format("YY.MM.DD")}
      </p>
      <p>{stripeStatusToText(invoice.status)}</p>
    </div>
  );
};

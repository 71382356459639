import { useAuth } from "@ocf/react-auth";
import { useQuery } from "react-query";
import { QUERIES } from "constants/queries";
import { Filters } from "types/invoice";

export const useGetInvoices = (filters?: Filters, awaitingData?: boolean) => {
  const { token } = useAuth();
  const mutation = useQuery(
    QUERIES.getInvoices(token as string, filters, awaitingData)
  );

  return mutation;
};

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "@mui/material";
import { useAuth } from "@ocf/react-auth";
import { useAuth as useCustomAuth } from "hooks/users/useAuth";

import { useNavigation } from "hooks/useNavigation";
import { AuthLayout } from "ui/layouts/auth-form/AuthForm";
import { useNotifications } from "hooks/useNotifications";

import {
  RegisterForm,
  registerSchema,
  defaultValues,
  errorCodeToString,
} from "./utils";
import { Form } from "./styles";
import { buildField } from "../buildField";
import TagManager from "react-gtm-module";
import GoogleButton from "react-google-button";
import { Divider } from "ui/atoms";
import { useEffect } from "react";

export const Register = () => {
  const { goToSignin, goToConfirmAccount, goToHome } = useNavigation();
  const { signupWithEmail, overrideToken, overrideRefreshToken } = useAuth();
  const { loginWithGoogleSSO, getAuthUser } = useCustomAuth();
  const { pushNotification } = useNotifications();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<RegisterForm>({
    resolver: yupResolver(registerSchema),
    defaultValues,
  });

  useEffect(() => {
    if (typeof window !== "undefined") {
      const params = new URL(window.location.href).searchParams;
      const ggsso = params.get("ggsso") === "signin";

      if (ggsso) {
        getAuthUser(({ err, data }: any) => {
          if (err) {
            console.log({ err });
          } else {
            const token = data?.signInUserSession?.accessToken?.jwtToken;
            const refreshToken =
              data?.signInUserSession?.refreshToken?.jwtToken;

            if (token) {
              overrideToken(token);
            }

            if (refreshToken) {
              overrideRefreshToken(refreshToken);
            }

            goToHome();
          }
        });
      }
    }
  }, []);

  const fields = [
    buildField(
      "firstname",
      "Firstname",
      errors?.firstname?.message?.toString()
    ),
    buildField("lastname", "Lastname", errors?.lastname?.message?.toString()),
    buildField("email", "Email", errors?.email?.message?.toString()),
    buildField("password", "Password", errors?.password?.message?.toString(), {
      type: "password",
    }),
    buildField(
      "confirmPassword",
      "Confirm password",
      errors?.confirmPassword?.message?.toString(),
      {
        type: "password",
      }
    ),
  ];

  const onSubmit = async (data: RegisterForm) => {
    const options = {
      onSuccess: () => {
        TagManager.dataLayer({
          dataLayer: {
            event: "register",
          },
        });
        goToConfirmAccount(data.email);
      },
      onFailure: (err: { code: string }) => {
        pushNotification(errorCodeToString(err.code), "error");
      },
    };
    // TODO: update the lib to pass dynamic values
    await signupWithEmail(
      data.email,
      data.password,
      { lastname: data.lastname, firstname: data.firstname },
      options
    );

    localStorage.setItem("onboarding", "true");
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <AuthLayout
        control={control}
        fields={fields}
        title="Invoices Magic Bucket"
        subtitle="All invoices, One place."
        sectionTitle="Register"
        renderButtons={() => (
          <>
            <Button variant="contained" type="submit">
              Register
            </Button>

            <Divider label="or" />

            <div className="flex w-full flex-col">
              <GoogleButton
                onClick={loginWithGoogleSSO}
                type="light"
                label="Sign Up with Google"
                style={{ width: "100%", borderRadius: 4 }}
              />
            </div>

            <div className="mt-4 flex flex-row items-center justify-center gap-2">
              <p className="text-sm text-neutral-400">
                <span>{"Already have an account? "}</span>
                <button
                  onClick={goToSignin}
                  className="border-none bg-transparent text-[#11998e] underline"
                >
                  Sign In
                </button>
              </p>
            </div>
          </>
        )}
      />
    </Form>
  );
};

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Divider } from "@mui/material";
import { useAuth } from "@ocf/react-auth";

import { useNavigation } from "hooks/useNavigation";
import { AuthLayout } from "ui/layouts/auth-form/AuthForm";
import { useNotifications } from "hooks/useNotifications";

import { Form } from "../signin/styles";
import { buildField } from "../buildField";

import {
  defaultValues,
  ForgotPasswordForm,
  forgotPasswordSchema,
} from "./utils";

export const ForgotPassword = () => {
  const { goToSignin, goToResetPassword } = useNavigation();
  const { sendResetPassword } = useAuth();
  const { pushNotification } = useNotifications();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ForgotPasswordForm>({
    resolver: yupResolver(forgotPasswordSchema),
    defaultValues,
  });

  const fields = [
    buildField("email", "Email", errors?.email?.message?.toString()),
  ];

  const onSubmit = async (data: ForgotPasswordForm) => {
    await sendResetPassword(data.email, {
      onSuccess: () => {
        pushNotification(`An email has been sent to ${data.email}`, "success");
        goToResetPassword({
          email: data.email,
        });
      },
      onFailure: () => {
        pushNotification(
          "An error occured while trying to reset password",
          "error"
        );
      },
    });
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <AuthLayout
        control={control}
        fields={fields}
        title="Invoices Magic Bucket"
        subtitle="All invoices, One place."
        sectionTitle="Forgot Password"
        renderButtons={() => (
          <>
            <Button variant="contained" type="submit">
              Send me an email
            </Button>
            <Divider />
            <Button variant="text" onClick={goToSignin}>
              Go back to SignIn
            </Button>
          </>
        )}
      />
    </Form>
  );
};

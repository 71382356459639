import { QueryClient, UseMutationOptions, UseQueryOptions } from "react-query";
import * as FileService from "api/file";
import * as UserService from "api/user";
import { Filters } from "types/invoice";

export const QUERY_KEYS = {
  getInvoices: "GET-INVOICES",
  getInvoiceUrl: "GET-INVOICE-URL",
  uploadFile: "UPLOAD-FILE",
  deleteFiles: "DELETE-FILES",
  downloadInvoices: "DOWNLOAD-INVOICES",
};

interface UploadFileData {
  file: File;
}

const uploadFile = (
  queryClient: QueryClient,
  token: string,
  hasAccess: boolean,
  mutationOptions?: UseMutationOptions<any, any, UploadFileData>,
  options?: any
): UseMutationOptions<any, any, UploadFileData> => ({
  ...mutationOptions,
  mutationKey: [QUERY_KEYS.uploadFile],
  mutationFn: async (data: UploadFileData) => {
    if (hasAccess) {
      return await FileService.uploadFile(
        token,
        data.file,
        options?.onProgress
      );
    }
  },
  onSuccess: (data, variables, context) => {
    queryClient.invalidateQueries(QUERY_KEYS.getInvoices);
    queryClient.invalidateQueries(["users", "profile"]);

    if (mutationOptions?.onSuccess) {
      mutationOptions.onSuccess(data, variables, context);
    }
  },
});

interface DeleteFilesData {
  fileIds: string[];
}

const deleteFiles = (
  queryClient: QueryClient,
  token: string,
  queryOptions?: any
): UseMutationOptions<any, any, DeleteFilesData> => ({
  ...queryOptions,
  mutationKey: [QUERY_KEYS.deleteFiles],
  mutationFn: async (data: DeleteFilesData) => {
    return await FileService.deleteFiles(token, data.fileIds);
  },
  onSuccess: (data, variables, context) => {
    queryClient.invalidateQueries(QUERY_KEYS.getInvoices);

    if (queryOptions?.onSuccess) {
      queryOptions.onSuccess(data, variables, context);
    }
  },
});

const buildFiltersQueryKey = (filters?: Filters) => {
  const filtersQueryKey = [
    ...(filters?.date?.start ? [filters.date.start] : []),
    ...(filters?.date?.end ? [filters.date.end] : []),
  ];
  return filtersQueryKey;
};

const getInvoices = (
  token?: string,
  filters?: Filters,
  awaitingData?: boolean
): UseQueryOptions<any, any, any, any> => ({
  queryKey: [QUERY_KEYS.getInvoices, ...buildFiltersQueryKey(filters)],
  queryFn: async () => {
    return await UserService.getAllFilesData(token as string, filters);
  },
  enabled: !!token,
  refetchInterval: awaitingData ? 2000 : false,
});

const getInvoiceUrl = (
  token: string,
  fileId: string
): UseQueryOptions<any, any, any, any> => ({
  queryKey: [QUERY_KEYS.getInvoiceUrl, { id: fileId }],
  queryFn: async () => {
    return await FileService.getInvoiceUrl(token as string, fileId);
  },
  enabled: !!token,
});

interface DownloadInvoicesData {
  invoiceIds: string[];
  type: "PDF" | "CSV";
}

const downloadFile = (data: Blob, filename: string) => {
  // const href = URL.createObjectURL(data);
  const link = document.createElement("a");
  link.setAttribute("href", "data:text/plain;base64," + data);
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const downloadInvoices = (
  _queryClient: QueryClient,
  token: string
): UseMutationOptions<any, any, DownloadInvoicesData> => ({
  mutationKey: [QUERY_KEYS.downloadInvoices],
  mutationFn: async (data: DownloadInvoicesData) => {
    return await FileService.downloadInvoices(token, data);
  },
  onSuccess: (data) => {
    if (data) {
      downloadFile(data, "invoices.zip");
    }
  },
});

// TODO: move update here

export const QUERIES = {
  uploadFile,
  deleteFiles,
  getInvoices,
  getInvoiceUrl,
  downloadInvoices,
};

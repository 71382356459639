import moment from "moment";

import { ChipId, IntervalKind } from "./FiltersPopover.types";

export const dateToTimestamp = (date: string, isEnd = false) => {
  const jsDate = new Date(date);

  if (isEnd) {
    jsDate.setHours(23);
    jsDate.setMinutes(59);
    jsDate.setSeconds(59);
  } else {
    jsDate.setHours(0);
    jsDate.setMinutes(0);
    jsDate.setSeconds(0);
  }

  return jsDate.getTime();
};

export const getDateInterval = (
  kind: IntervalKind,
  pointerFromCurrent?: number
) => {
  let date = moment();

  if (pointerFromCurrent) {
    date = date.add(pointerFromCurrent, `${kind}s`);
  }

  const start = moment(date).startOf(kind === "week" ? "isoWeek" : kind);
  const end = moment(date).endOf(kind === "week" ? "isoWeek" : kind);

  return {
    start: start.format("YYYY-MM-DD"),
    end: end.format("YYYY-MM-DD"),
  };
};

export const mapChipIdToInterval = (
  chipId: ChipId
): [Nullable<IntervalKind>, Nullable<number>] => {
  switch (chipId) {
    case "s":
      return ["week", 0];
    case "s-1":
      return ["week", -1];
    case "m":
      return ["month", 0];
    case "m-1":
      return ["month", -1];
    case "a":
      return ["year", 0];
    case "a-1":
      return ["year", -1];
    default:
      return [null, null];
  }
};

import { useQuery, UseQueryOptions } from "react-query";
import { useAuth } from "@ocf/react-auth";
import { getSubscriptionDetails } from "api/user";

export interface UseSubscriptionDetailsOptions {
  subscriptionId?: string;
  queryOptions?: UseQueryOptions<any>;
}

export const useSubscriptionDetails = (
  options: UseSubscriptionDetailsOptions
) => {
  const { token } = useAuth();

  const queryOptions: UseQueryOptions<any> = {
    queryKey: ["users", "subscriptions", { id: options?.subscriptionId }],
    queryFn: () =>
      getSubscriptionDetails(token ?? "", options?.subscriptionId ?? ""),
    enabled: !!token && !!options?.subscriptionId,
    ...options?.queryOptions,
  };

  return useQuery(queryOptions);
};

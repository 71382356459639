import React from "react";

import { useTabs } from "./TabsContext";

export interface TabPanelProps {
  tab: string | number;
}

export const TabPanel: React.FC<React.PropsWithChildren<TabPanelProps>> = (
  props
) => {
  const { tab, children } = props;
  const { activeTab } = useTabs();

  if (tab !== activeTab) {
    return null;
  }

  return <>{children}</>;
};

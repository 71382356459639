import React, { createContext } from "react";
import { useSnackbar, OptionsObject, SnackbarKey } from "notistack";
import { Box, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";

type Kind = "error" | "info" | "warning" | "success";

const init = {
  pushNotification: () => {
    return "";
  },
  closeNotification: () => {
    //
  },
};

interface NotificationsContextType {
  pushNotification: (message: string, kind: Kind) => string;
  closeNotification: (key: string) => void;
}

export const NotificationsContext =
  createContext<NotificationsContextType>(init);

const getOptions = (kind: Kind, onClose: (id: SnackbarKey) => void) => {
  const options: OptionsObject = {};

  if (["error", "warning"].includes(kind)) {
    options.persist = true;
    options.action = (id: SnackbarKey) => (
      <Box>
        <IconButton onClick={() => onClose(id)}>
          <Close />
        </IconButton>
      </Box>
    );
  }

  options.variant = kind;

  return options;
};

export const NotificationsProvider: React.FC<React.PropsWithChildren> = (
  props
) => {
  const { children } = props;
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();

  const handleClose = (id: SnackbarKey) => {
    closeSnackbar(id);
  };

  const value = {
    pushNotification: (message: string, kind: Kind = "info") => {
      const options = getOptions(kind, (id: SnackbarKey) => handleClose(id));
      return enqueueSnackbar(message, {
        ...options,
      }).toString();
    },
    closeNotification: (key: string) => {
      handleClose(key);
    },
  };

  return (
    <NotificationsContext.Provider value={value}>
      {children}
    </NotificationsContext.Provider>
  );
};

import React, { useRef, useEffect, useState } from "react";
import { Modal, Typography, Button, IconButton } from "@mui/material";
import {
  CloudUpload,
  Close,
  Visibility,
  Loop,
  Done,
  Lightbulb,
} from "@mui/icons-material";
import { PDFDocument } from "pdf-lib";

import {
  Container,
  DropAreaContainer,
  DropInput,
  ButtonsContainer,
} from "./styles";
import { createFileUrl } from "../../utils/file";
import { useUploadFile } from "hooks/files/useUploadFile";
import { FileVisualizer } from "../file-visualizer/FileVisualizer";
import { useInvoices } from "features/home/providers/InvoicesProvider";
import { useProfile, useRole } from "hooks/users";

const LIMIT = 10;

interface UploadModalProps {
  open: boolean;
  onClose: () => void;
}

const FileUploadRow = (props: any) => {
  const { filename, file, unsupported, error, onClear } = props;
  const [previewUrl, setPreviewUrl] = useState("");
  const [uploaded, setUploaded] = useState(false);
  const [fileId, setFileId] = useState("");
  const [visualiseOpen, setVisualiseOpen] = useState(false);
  const { onStartAwaitData } = useInvoices();
  const { mutate: uploadFile, isLoading } = useUploadFile({
    queryOptions: {
      onSuccess: (data: any) => {
        setFileId(data.fileId);
        setUploaded(true);
        onStartAwaitData();
      },
    },
  });

  useEffect(() => {
    if (!unsupported) {
      createFileUrl(file, async (result) => {
        try {
          // eslint-disable-next-line
				// @ts-ignore
          const test = await PDFDocument.load(result);
          if (test) {
            const document = await PDFDocument.create();
            const copied = await document.copyPages(test, [0]);
            document.addPage(copied[0]);
            const uri = await document.saveAsBase64({ dataUri: true });
            const dataURItoBlob = (dataURI: any) => {
              // convert base64 to raw binary data held in a string
              // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
              const byteString = atob(dataURI.split(",")[1]);

              // separate out the mime component
              const mimeString = dataURI
                .split(",")[0]
                .split(":")[1]
                .split(";")[0];

              // write the bytes of the string to an ArrayBuffer
              const ab = new ArrayBuffer(byteString.length);
              const ia = new Uint8Array(ab);
              for (let i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
              }

              return new Blob([ab], {
                type: mimeString,
                // eslint-disable-next-line
							// @ts-ignore
                name: filename,
              });
            };
            const blob = dataURItoBlob(uri);
            const file = new File([blob], filename);
            return createFileUrl(file, async (result) => {
              setPreviewUrl(result);
              uploadFile({
                file,
              });
            });
          }
        } catch (err) {
          //
        }
      });
    }
  }, []);

  const getNextMetric = (metric: "octets" | "ko" | "mb" | "go") => {
    switch (metric) {
      case "octets":
        return "ko";
      case "ko":
        return "mb";
      case "mb":
        return "go";
      default:
        return "octets";
    }
  };

  const cutSize = (size: number) => Math.floor(size / 10) / 100;

  const formatSize = (
    size: number,
    metric: "octets" | "ko" | "mb" | "go" = "octets"
  ): string => {
    if (size < 999 || metric === "go") {
      return `${size.toFixed(2)} ${metric}`;
    }

    return formatSize(cutSize(size), getNextMetric(metric));
  };

  const openPreview = () => {
    if (fileId) {
      setVisualiseOpen(true);
    }
  };

  return (
    <>
      <div className="flex flex-row items-center gap-4 rounded bg-white px-4 py-2 shadow-lg">
        <div className="flex flex-1 flex-row items-center gap-4">
          {!unsupported && isLoading && !uploaded ? (
            <div className="animation-reverse flex h-full animate-spin flex-col items-center justify-center">
              <Loop fontSize="small" />
            </div>
          ) : uploaded && !unsupported ? (
            <div>
              <Done fontSize="small" />
            </div>
          ) : (
            <div>
              <Close fontSize="small" />
            </div>
          )}
          <div className="flex flex-1 flex-col gap-1">
            <p className="line-clamp-1 flex-1 font-medium">{filename}</p>
            <div className="flex flex-row items-center gap-2">
              <p className="text-sm italic text-neutral-400">
                {formatSize(file.size)}
              </p>
              {unsupported && (
                <p className="text-xs font-medium italic text-red-700">
                  {error}
                </p>
              )}
            </div>
          </div>
        </div>

        <div className="flex flex-row items-center gap-2">
          <IconButton disabled={!previewUrl} onClick={openPreview}>
            <Visibility fontSize="small" />
          </IconButton>

          <IconButton
            disabled={!uploaded && !!fileId}
            onClick={() => {
              onClear(filename);
            }}
          >
            <Close fontSize="small" color="error" />
          </IconButton>
        </div>
      </div>

      {visualiseOpen && (
        <FileVisualizer
          fileId={fileId}
          onClose={() => setVisualiseOpen(false)}
        />
      )}
    </>
  );
};

const Quantity: React.FC<any> = (props) => {
  const { quantity } = props;
  const { data: userProfile } = useProfile();
  const { role } = useRole();

  if (role === "FREE") {
    return (
      <>
        <p className="text-sm font-semibold text-red-500">
          {userProfile?.data?.remainingTries} free trials remaining
        </p>
        <p className="flex-1 text-sm font-medium text-orange-500">{`${userProfile?.data?.remainingTries}/3`}</p>
      </>
    );
  }

  return (
    <p className="flex-1 text-sm font-medium text-neutral-500">{`${quantity}/10`}</p>
  );
};

export const UploadModal: React.FC<UploadModalProps> = (props) => {
  const { open, onClose } = props;
  const [previewUrl, setPreviewUrl] = useState("");
  const previewRef = useRef<any>(null);
  const [files, setFiles] = useState<any[]>([]);
  const [tipOpen, setTipOpen] = useState(true);
  const [globalError, setGlobalError] = useState<null | string>(null);
  const { data: profile } = useProfile();
  const { role } = useRole();

  useEffect(() => {
    if (!open) {
      setPreviewUrl("");
      previewRef.current = null;
      setFiles([]);
    }
  }, [open]);

  const createUrls = () => {
    if (
      files.length + previewRef.current?.files?.length > LIMIT ||
      (role === "FREE" &&
        files.length + previewRef.current?.files?.length >
          profile?.data?.remainingTries)
    ) {
      setGlobalError("File limit reached");
      return;
    }

    if (previewRef.current?.files?.length) {
      const filesData = Array.prototype.map.call(
        previewRef.current.files,
        (file: File) => {
          const unsupported = file.size > 200_000_000;

          return {
            filename: file.name,
            rawFile: file,
            unsupported,
            error: unsupported ? "File size is over 2MB" : "",
          };
        }
      );
      setFiles((prevState) => [...prevState, ...filesData]);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Container>
        <div className="px-4">
          <div className="flex flex-col gap-2">
            <p className="text-xl font-medium">Upload file</p>
            <div className="flex flex-row items-center gap-4">
              <Quantity quantity={files.length} />
              <IconButton onClick={() => setTipOpen(!tipOpen)}>
                <Lightbulb fontSize="small" />
              </IconButton>
            </div>
          </div>

          {tipOpen &&
            (profile?.data?.role !== "FREE" ? (
              <div className="flex w-full flex-row gap-2 rounded bg-[#97BFDD] p-4 text-xs leading-6">
                <Lightbulb fontSize="small" color="primary" />
                <p>
                  {
                    "You can also process your invoices by sending them by email to "
                  }
                  <a
                    className="whitespace-nowrap rounded bg-[#173449] p-1 text-white"
                    href={`mailto:${"invoices@app.invoicemagicbucket.com"}`}
                  >
                    {"invoices@app.invoicemagicbucket.com"}
                  </a>
                  {
                    " (you must use the exact same email address that you registered on this account) or use your Invoice Magic Bucket's generated email "
                  }
                  <a
                    className="whitespace-nowrap rounded bg-[#173449] p-1 text-white"
                    href={`mailto:${profile?.data?.emailAlias}`}
                  >
                    {profile?.data?.emailAlias}
                  </a>
                </p>
              </div>
            ) : (
              <div className="flex w-full flex-row gap-2 rounded bg-[#97BFDD] p-4 text-xs leading-6">
                <Lightbulb fontSize="small" color="primary" />
                <p>
                  {
                    "Subscribe to our service and you can also automatically process your invoices by sending them by email!"
                  }
                </p>
              </div>
            ))}

          <DropAreaContainer $active={!!previewUrl}>
            <DropInput
              ref={previewRef}
              onChange={createUrls}
              type="file"
              accept=".pdf"
              multiple
            />
            <Typography>Drag and drop an invoice here or click</Typography>
            <CloudUpload />
          </DropAreaContainer>

          <div className="flex flex-row items-center gap-4">
            <p className="mt-4 flex-1 text-sm font-medium text-red-600">
              {globalError ?? ""}
            </p>

            {files.length > 0 && (
              <Button
                onClick={() => {
                  setFiles([]);
                  setGlobalError(null);
                }}
              >
                Clear
              </Button>
            )}
          </div>
        </div>

        <div className="mt-2 flex max-h-[35vh] flex-col gap-2 overflow-auto p-4">
          {files.map((file) => (
            <FileUploadRow
              key={file.filename}
              filename={file.filename}
              file={file.rawFile}
              unsupported={file.unsupported}
              error={file.error}
              onClear={(filename: string) =>
                setFiles(files.filter((f) => f.filename !== filename))
              }
            />
          ))}
        </div>

        <div className="px-4">
          <ButtonsContainer>
            <Button onClick={onClose} variant="contained">
              Done
            </Button>
          </ButtonsContainer>
        </div>
      </Container>
    </Modal>
  );
};

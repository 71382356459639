import React, { useState } from "react";
import {
  IconButton,
  Badge,
  MenuItem,
  Menu,
  CircularProgress,
  Box,
} from "@mui/material";
import { green } from "@mui/material/colors";
import { FileDownload } from "@mui/icons-material";
import { useDownloadInvoices } from "hooks/files/useDownloadInvoices";

import { useFilesActions } from "./FilesActionsProvider";

export interface FileDownloadButtonsProps {
  selectedToDownload: string[];
}

export const FileDownloadButtons: React.FC<FileDownloadButtonsProps> = (
  props
) => {
  const { selectedToDownload } = props;
  const [menuOpen, setMenuOpen] = useState<any>(null);
  const { selected } = useFilesActions();
  const { mutate, isLoading } = useDownloadInvoices();

  const download = (downloadType: "PDF" | "CSV") => async () => {
    const fileIds = selected.map((i) => i.id);
    mutate({ invoiceIds: fileIds, type: downloadType });
    setMenuOpen(false);
  };

  return (
    <>
      <Box sx={{ m: 1, position: "relative" }}>
        <IconButton
          onClick={(ev) => setMenuOpen(ev.currentTarget)}
          disabled={selectedToDownload?.length === 0}
        >
          <Badge badgeContent={selectedToDownload.length} color="primary">
            <FileDownload />
          </Badge>
        </IconButton>

        {isLoading && (
          <CircularProgress
            size={50}
            sx={{
              color: green[500],
              position: "absolute",
              top: -5,
              left: -5,
              zIndex: 1,
            }}
          />
        )}
      </Box>

      <Menu
        open={!!menuOpen}
        anchorEl={menuOpen}
        onClose={() => setMenuOpen(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem onClick={download("PDF")}>Download PDF</MenuItem>
        <MenuItem onClick={download("CSV")}>Download CSV</MenuItem>
      </Menu>
    </>
  );
};

import React, { useState } from "react";
import { Badge, IconButton } from "@mui/material";
import { Tune } from "@mui/icons-material";

import { FiltersPopover } from "./filters-popover";
import { useInvoices } from "features/home/providers/InvoicesProvider";

export const FilterInvoicesButton: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const { isFiltering } = useInvoices();
  const disabled = false;

  const handleOpen = (ev: any) => {
    setAnchorEl(ev.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={handleOpen} disabled={disabled}>
        <Badge invisible={!isFiltering} color="primary" variant="dot">
          <Tune />
        </Badge>
      </IconButton>

      <FiltersPopover
        open={!!anchorEl}
        onClose={handleClose}
        anchorEl={anchorEl}
      />
    </>
  );
};

import { useAuth } from "@ocf/react-auth";
import { useMutation, UseMutationOptions, useQueryClient } from "react-query";
import { useRole } from "hooks/users";
import { QUERIES } from "constants/queries";

export interface UseUploadFileOptions {
  queryOptions?: UseMutationOptions<any, any, any>;
  onProgress?: (progressData: any) => void;
}

export const useUploadFile = (options?: UseUploadFileOptions) => {
  const { token } = useAuth();
  const { hasAccess } = useRole();
  const queryClient = useQueryClient();

  const mutation = useMutation(
    QUERIES.uploadFile(
      queryClient,
      token as string,
      hasAccess("BASIC"),
      options?.queryOptions,
      {
        onProgress: options?.onProgress,
      }
    )
  );

  return mutation;
};

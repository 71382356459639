import { useMutation, UseMutationOptions, useQueryClient } from "react-query";
import { useAuth } from "@ocf/react-auth";
import { resumeSubscription } from "api/user";

export interface UseResumeSubscriptionOptions {
  queryOptions?: UseMutationOptions<any>;
}

export interface UseResumeSubscriptionForm {
  customerId: string;
  priceId: string;
}

export const useResumeSubscription = (
  options?: UseResumeSubscriptionOptions
) => {
  const { token } = useAuth();
  const client = useQueryClient();

  const queryOptions: UseMutationOptions<any> = {
    ...options?.queryOptions,
    mutationKey: ["users", "subscriptions"],
    mutationFn: () => resumeSubscription(token ?? ""),
    onSuccess: (data, variables, context) => {
      client.invalidateQueries({
        queryKey: ["users", "subscriptions"],
      });

      if (options?.queryOptions?.onSuccess) {
        options?.queryOptions?.onSuccess(data, variables, context);
      }
    },
  };

  return useMutation<any>(queryOptions);
};

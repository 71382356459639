import React from "react";
import { AuthProvider, cognito } from "@ocf/react-auth";
import { ThemeProvider } from "@mui/material";
import { SnackbarProvider } from "notistack";
import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

import { NotificationsProvider } from "./Notifications";

import { theme } from "theme";

const providerOptions = {
  clientId: process.env.REACT_APP_AWS_CLIENT_ID as string,
  userPoolId: process.env.REACT_APP_AWS_USERPOOL_ID as string,
};

const clientProvider = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 60,
      cacheTime: 1000 * 60 * 100, // !!! Should always be greater than staleTime
    },
  },
});

export const Providers: React.FC<React.PropsWithChildren> = (props) => {
  const { children } = props;

  return (
    <SnackbarProvider>
      <NotificationsProvider>
        <AuthProvider provider={cognito.provider(providerOptions)}>
          <QueryClientProvider client={clientProvider}>
            <ThemeProvider theme={theme}>{children}</ThemeProvider>
            <ReactQueryDevtools initialIsOpen />
          </QueryClientProvider>
        </AuthProvider>
      </NotificationsProvider>
    </SnackbarProvider>
  );
};

import React from "react";

export interface DividerProps {
  label?: string;
}

export const Divider: React.FC<DividerProps> = (props) => {
  const { label } = props;

  return (
    <div className="flex flex-row items-center gap-2">
      <div className="h-0 flex-1 border-[0.5px] border-neutral-300" />

      {label && (
        <>
          <p className="text-xs text-neutral-500">{label}</p>
          <div className="h-0 flex-1 border-[0.5px] border-neutral-300" />
        </>
      )}
    </div>
  );
};

import * as yup from "yup";
import { emailSchema, passwordSchema } from "utils/form-schemas";

export const registerSchema = yup.object({
  firstname: yup.string().required(),
  lastname: yup.string().required(),
  email: emailSchema,
  password: passwordSchema,
  confirmPassword: passwordSchema.oneOf(
    [yup.ref("password")],
    "passwords must match"
  ),
});

export interface RegisterForm {
  firstname: string;
  lastname: string;
  email: string;
  password: string;
  confirmPassword: string;
}

export const defaultValues: RegisterForm = {
  firstname: "",
  lastname: "",
  email: "",
  password: "",
  confirmPassword: "",
};

export const errorCodeToString = (code: string) => {
  switch (code) {
    case "100#01":
      return "This email address is not available";
    default:
      return "Something went wrong during this operation";
  }
};

import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

import React from "react";
import { Document, Page, pdfjs } from "react-pdf";

import { Paper, CircularProgress } from "@mui/material";

pdfjs.GlobalWorkerOptions.workerSrc = "/pdf.worker.min.js";

export interface FileViewerProps {
  url: string;
  height?: number;
  width?: number;
}

const Loader = () => {
  return (
    <Paper>
      <CircularProgress />
    </Paper>
  );
};

export const FileViewer: React.FC<FileViewerProps> = (props) => {
  const { url, height, width } = props;

  return (
    <Document file={{ url }} loading={<Loader />}>
      <Page
        pageNumber={1}
        height={(width ?? 0) * 1.414 <= (height ?? 0) ? undefined : height}
        width={(width ?? 0) * 1.414 > (height ?? 0) ? undefined : width}
      />
    </Document>
  );
};

import { styled, Drawer as MUIDrawer, IconButton, Box } from "@mui/material";

export const Container = styled("div")`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const Drawer = styled(MUIDrawer)`
  & .MuiPaper-root {
    padding: 0.5rem;
    background-color: ${({ theme }) => theme.palette.primary.main};
    box-shadow: 1px 0px 2px rgba(0, 0, 0, 0.09);
    color: ${({ theme }) => theme.palette.primary.contrastText};
  }
`;

export const IconBtn = styled(IconButton)`
  color: ${({ theme }) => theme.palette.error.light};
`;

export const TopContainer = styled(Box)`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

import React, { useRef } from "react";
import {
  Modal,
  Typography,
  TextField,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  LinearProgress,
} from "@mui/material";
import { useQueryClient } from "react-query";
import { useForm, Controller } from "react-hook-form";
import * as INVOICES from "constants/invoices";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "react-query";
import { useGetInvoiceUrl } from "hooks/files/useGetInvoiceUrl";
import { useAuth } from "@ocf/react-auth";
import { FileViewer } from "ui/atoms";
import * as FileService from "api/file";

import { FormContainer, ButtonsContainer, Button } from "./styles";

interface EditModalProps {
  invoice: any;
  open: boolean;
  onClose: () => void;
}

const formatDate = (date: string | number) => {
  const d = new Date(+date);
  const year = d.getFullYear();
  const month = d.getMonth() + 1;
  const day = d.getDate();
  return `${year}-${month < 10 ? `0${month}` : month}-${
    day < 10 ? `0${day}` : day
  }`;
};

const containExtension = (value: string) => /\./.test(value);

const invoiceSchema = yup
  .object({
    id: yup.string().required(),
    filename: yup
      .string()
      .test(
        "ext",
        "Filename must not contain dot or file extension",
        (value) => !containExtension(value ?? "")
      )
      .required(),
    currency: yup.string().required(),
    fileStatus: yup.string().required(),
    receiptId: yup.string().required(),
    subtotal: yup.string().required(),
    tax: yup.string().required(),
    category: yup
      .string()
      .oneOf(INVOICES.CATEGORIES.map((c) => c.key))
      .required(),
    total: yup.string().required(),
    uploadedAt: yup.string().required(),
    vendorName: yup.string().required(),
    date: yup.string().required(),
  })
  .required();

const BORDER_SIZE = 16;

export const EditModal: React.FC<EditModalProps> = (props) => {
  const { invoice, open, onClose } = props;
  const pdfParentRef = useRef<null | HTMLDivElement>(null);
  const { token } = useAuth();
  const queryClient = useQueryClient();
  const { data, isLoading } = useGetInvoiceUrl(invoice.id);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(invoiceSchema),
    defaultValues: {
      id: invoice.id,
      filename: invoice.filename,
      currency: invoice.currency,
      fileStatus: invoice.fileStatus,
      date: formatDate(invoice.date),
      receiptId: invoice.receiptId,
      category: invoice?.category ?? "to_categorize",
      subtotal: invoice.subtotal,
      total: invoice.total,
      tax: invoice.tax,
      vendorName: invoice.vendorName,
      uploadedAt: invoice.uploadedAt,
    },
  });

  const { mutate } = useMutation({
    mutationKey: ["update-invoice", invoice.id],
    mutationFn: async (data) => {
      await FileService.updateInvoiceData(token as string, data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries("GET-INVOICES");
    },
  });

  const onCancel = () => {
    onClose();
  };

  const onSubmit = async (data: any) => {
    const [year, month, date] = data.date.split("-");
    const jsDate = new Date();
    jsDate.setDate(date);
    jsDate.setMonth(month - 1);
    jsDate.setFullYear(year);
    mutate({
      ...data,
      date: jsDate.getTime(),
    });
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <div className="fixed inset-0 flex flex-row gap-2">
        <div className="flex h-full grow p-4">
          <div
            className="flex h-full w-full flex-col items-center justify-center bg-white"
            ref={pdfParentRef}
          >
            {!data && isLoading && <LinearProgress />}

            {data && !isLoading && (
              <FileViewer
                url={data}
                height={pdfParentRef.current?.getBoundingClientRect()?.height}
                width={pdfParentRef.current?.getBoundingClientRect()?.width}
              />
            )}
          </div>
        </div>

        <div className="flex h-full w-[500px] flex-col bg-white p-4 shadow">
          <Typography variant="h6" sx={{ marginBottom: "1rem" }}>
            {invoice.filename}
          </Typography>

          <FormContainer onSubmit={handleSubmit(onSubmit)} id="edit-invoice">
            <Stack direction="column" gap={2} flexGrow={1}>
              {invoice &&
                (Object?.keys(invoice) as (keyof typeof invoiceSchema)[])
                  ?.filter(
                    (key: any) => !["uploadedAt", "category"].includes(key)
                  )
                  ?.map((item: any) => (
                    <Controller
                      key={item}
                      name={item}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          error={!!(errors as any)[item]}
                          helperText={(errors as any)[item]?.message}
                          value={field.value}
                          onChange={field.onChange}
                          disabled={["id", "fileStatus"].includes(item)}
                          key={item}
                          {...(item === "date"
                            ? { type: "date", label: "" }
                            : { label: item })}
                        />
                      )}
                    />
                  ))}

              <Controller
                name="category"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Category
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={field.value}
                      label="Category"
                      onChange={field.onChange}
                    >
                      {INVOICES.CATEGORIES.map((category) => (
                        <MenuItem value={category.key} key={category.key}>
                          {category.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            </Stack>
          </FormContainer>

          <ButtonsContainer>
            <Button variant="contained" type="submit" form="edit-invoice">
              Save
            </Button>
            <Button onClick={onCancel}>Cancel</Button>
          </ButtonsContainer>
        </div>
      </div>
    </Modal>
  );
};

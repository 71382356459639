import { Bars } from "react-loader-spinner";

import {
  useProfile,
  useSubscriptionDetails,
  useSubscriptionsPlans,
} from "hooks/users";
import { useNavigation } from "hooks/useNavigation";

import { SubscriptionCard } from "../subscription-panel/SubscriptionCard";
import { sortByPrice } from "../subscription-panel/utils";
import { useEffect } from "react";

export const SubscriptionPlans = () => {
  const { data: userProfile } = useProfile();
  const { data: subDetails } = useSubscriptionDetails({
    subscriptionId: userProfile?.data?.stripeSubscriptionId ?? "",
  });
  const { data: subscriptionsPlans, isLoading } = useSubscriptionsPlans();
  const { subscriptions } = useNavigation();

  useEffect(() => {
    if (
      !!subDetails?.data &&
      subDetails?.data?.status === "active" &&
      !subDetails?.data?.canceled
    ) {
      subscriptions.goToDetails();
    }
  }, [subDetails?.data]);

  if (isLoading) {
    return (
      <div className="flex h-full w-full flex-col items-center justify-center">
        <Bars width={32} height={32} color="#11998e" />
      </div>
    );
  }

  const onSelectPlan = (planId: string) => {
    subscriptions.goToSummary({
      planId,
    });
  };

  return (
    <div className="flex h-full w-full flex-row justify-center">
      {subscriptionsPlans?.data?.sort(sortByPrice).map((subscription: any) => (
        <SubscriptionCard
          onSelectPlan={onSelectPlan}
          key={subscription.id}
          name={subscription.name}
          stripeId={subscription.id}
          best={subscription.id === "prod_NjhhviIZfJ2n5q"}
          monthlyPrice={subscription.prices.find(
            (p: any) => p.recurringInterval === "month"
          )}
          yearlyPrice={subscription.prices.find(
            (p: any) => p.recurringInterval === "year"
          )}
        />
      ))}
    </div>
  );
};

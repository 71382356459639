import { TextFieldProps } from "@mui/material";

export const buildField = (
  name: string,
  label: string,
  error?: string,
  textFieldOptions?: TextFieldProps
) => {
  return {
    name,
    label,
    key: name,
    textFieldProps: {
      error: !!error,
      helperText: error,
      ...textFieldOptions,
    },
  };
};

export const CATEGORIES = [
  { key: "rent_or_mortgage", label: "Rent or Mortgage" },
  { key: "utilities", label: "Utilities" },
  { key: "office_supplies", label: "Office Supplies" },
  { key: "software_subscriptions", label: "Software Subscriptions" },
  { key: "hardware", label: "Hardware" },
  { key: "professional_services", label: "Professional Services" },
  { key: "advertising_and_marketing", label: "Advertising and Marketing" },
  { key: "travel_and_entertainment", label: "Travel and Entertainment" },
  {
    key: "employee_salaries_and_benefits",
    label: "Employee Salaries and Benefits",
  },
  { key: "insurance", label: "Insurance" },
  { key: "taxes", label: "Taxes" },
  { key: "repairs_and_maintenance", label: "Repairs and Maintenance" },
  {
    key: "training_and_professional_development",
    label: "Training and Professional Development",
  },
  { key: "telecommunications", label: "Telecommunications" },
  { key: "shipping_and_postage", label: "Shipping and Postage" },
  { key: "inventory_purchases", label: "Inventory Purchases" },
  { key: "research_and_development", label: "Research and Development" },
  { key: "vehicle_expenses", label: "Vehicle Expenses" },
  { key: "bank_fees_and_interest", label: "Bank Fees and Interest" },
  {
    key: "depreciation_and_amortization",
    label: "Depreciation and Amortization",
  },
  {
    key: "memberships_and_subscriptions",
    label: "Memberships and Subscriptions",
  },
  { key: "legal_and_regulatory_fees", label: "Legal and Regulatory Fees" },
  { key: "contractor_payments", label: "Contractor Payments" },
  { key: "meals_and_entertainment", label: "Meals and Entertainment" },
  { key: "miscellaneous_expenses", label: "Miscellaneous Expenses" },
  { key: "to_categorize", label: "To Categorize" },
];

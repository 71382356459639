import React, { createContext, useContext, useEffect, useState } from "react";
import { useGetInvoices } from "hooks/files/useGetInvoices";
import { Filters } from "types/invoice";

interface InvoicesValue {
  data: { data: { invoices: any[] } } | undefined;
  isLoading: boolean;
  awaitingData: boolean;
  isFiltering: boolean;
}

interface OnStartOptions {
  awaitTime?: number;
}

interface InvoicesMethods {
  applyFilters: (filters: Filters) => void;
  onStartAwaitData: (options?: OnStartOptions) => void;
  onStopAwaitData: () => void;
}

type InvoicesState = InvoicesValue & InvoicesMethods;

const initialState: InvoicesState = {
  data: undefined,
  isLoading: false,
  awaitingData: false,
  isFiltering: false,
  applyFilters: () => {
    //
  },
  onStartAwaitData: (options?: OnStartOptions) => {
    //
  },
  onStopAwaitData: () => {
    //
  },
};

export const InvoicesContext = createContext<InvoicesState>(initialState);

const seconds = (sec: number) => sec * 1000;

const defaultOptions: OnStartOptions = {
  awaitTime: seconds(30),
};

export const InvoicesProvider: React.FC<React.PropsWithChildren> = (props) => {
  const { children } = props;
  const [filters, setFilters] = useState<Filters>({});
  const { data, isLoading, isStale } = useGetInvoices(
    filters,
    filters.awaitingData
  );
  let timeout: undefined | NodeJS.Timeout;

  const onStartAwaitData = (options = defaultOptions) => {
    setFilters((prevState) => ({
      ...prevState,
      awaitingData: true,
    }));

    if (timeout) {
      clearTimeout(timeout);
      timeout = undefined;
    }

    timeout = setTimeout(() => {
      onStopAwaitData();
    }, options.awaitTime);
  };

  const onStopAwaitData = () => {
    setFilters((prevState) => ({
      ...prevState,
      awaitingData: false,
    }));
  };

  useEffect(() => {
    if (filters.awaitingData && !isStale && !isLoading) {
      const hasAwaiting = (data?.data?.invoices ?? [])?.filter((invoice: any) =>
        ["PRE_UPLOAD", "ANALYZING"].includes(invoice.fileStatus)
      );

      if (hasAwaiting.length === 0) {
        onStopAwaitData();

        if (timeout) {
          clearTimeout(timeout);
        }
      }
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [data, filters.awaitingData]);

  const value = {
    data,
    isLoading,
    awaitingData: !!filters.awaitingData,
    applyFilters: (filters: Filters) => {
      setFilters(filters);
    },
    onStartAwaitData,
    onStopAwaitData,
    isFiltering: !!(
      filters?.date?.start ||
      filters?.date?.end ||
      filters?.total
    ),
  };

  return (
    <InvoicesContext.Provider value={value}>
      {children}
    </InvoicesContext.Provider>
  );
};

export const useInvoices = () => {
  return useContext(InvoicesContext);
};

import React, { useState, useEffect } from "react";
import { useAuth } from "@ocf/react-auth";
import { Navigate } from "react-router-dom";

export const Protected: React.FC<React.PropsWithChildren> = (props) => {
  const { children } = props;
  const { loading, token } = useAuth();
  const [firstRender, setFirstRender] = useState(true);

  useEffect(() => {
    // TODO: This is a workaround, best solution is to initite the loaading state
    // of @ocf/react-auth to true
    if (!token && !loading && firstRender) {
      setFirstRender(false);
    }
  }, []);

  if (loading) {
    return <p>Loading</p>;
  } else if (!token && !firstRender) {
    return <Navigate to="/signin" />;
  }

  return <>{children}</>;
};

import {
  Paper,
  IconButton,
  Button as MUIButton,
  Stack,
  styled,
  Modal,
  Typography,
} from "@mui/material";

export const Container = styled(Paper)`
  padding: 0.5rem;
`;

export const MainActionsContainer = styled(Stack)`
  flex-grow: 1;
`;

export const ModalContainer = styled(Paper)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1rem;
  gap: 2rem;
  min-width: 600px;
  display: flex;
  flex-direction: column;
`;

export const Button = styled(MUIButton)``;

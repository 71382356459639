import React from "react";
import { Bars } from "react-loader-spinner";

import classNames from "classnames";

export interface ButtonProps {
  label: string;
  loading?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  type?: "button" | "submit" | "reset";
}

export const Button: React.FC<ButtonProps> = (props) => {
  const { label, loading, fullWidth, disabled, onClick, type } = props;

  return (
    <button
      disabled={disabled}
      onClick={onClick}
      type={type}
      className={classNames(
        "rounded bg-primary px-4 py-2 transition-all duration-300 hover:bg-primary-darker",
        fullWidth ? "w-full" : "w-fit"
      )}
    >
      {loading ? (
        <Bars width={24} height={24} color="white" />
      ) : (
        <p className="text-center text-on-primary">{label}</p>
      )}
    </button>
  );
};

import { Button, Divider } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation } from "react-router-dom";
import { useAuth } from "@ocf/react-auth";
import { useNotifications } from "hooks/useNotifications";

import { AuthLayout } from "ui/layouts/auth-form/AuthForm";

import { useNavigation } from "hooks/useNavigation";
import { b64_to_utf8 } from "utils/base64";
import { buildField } from "../buildField";

import {
  ConfirmAccountForm,
  confirmAccountSchema,
  defaultValues,
  errorCodeToString,
} from "./utils";
import { Form } from "./styles";

export const ConfirmAccount = () => {
  const { goToSignin } = useNavigation();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const { pushNotification } = useNotifications();
  const { confirmAccount, sendConfirmAccountCode } = useAuth();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ConfirmAccountForm>({
    resolver: yupResolver(confirmAccountSchema),
    defaultValues,
  });

  const handleGenerateNewCode = async () => {
    const base64Email = searchParams.get("email");

    if (base64Email) {
      const options = {
        onSuccess: () => {
          pushNotification("An email has been sent with a new code", "success");
        },
        onFailure: () => {
          pushNotification("An error occured, please try again", "error");
        },
      };
      await sendConfirmAccountCode(b64_to_utf8(base64Email), options);
    }
  };

  const fields = [
    buildField("code", "Code", errors?.code?.message?.toString()),
  ];

  const onSubmit = async (formData: ConfirmAccountForm) => {
    const base64Email = searchParams.get("email");

    if (base64Email) {
      const options = {
        onSuccess: () => {
          pushNotification(
            "Your account is confirmed, you can now log in",
            "success"
          );
          goToSignin();
        },
        onFailure: (err: { code: string }) => {
          pushNotification(errorCodeToString(err.code), "error");
        },
      };
      await confirmAccount(b64_to_utf8(base64Email), formData.code, options);
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <AuthLayout
        subtitle="Confirm your account"
        control={control}
        fields={fields}
        renderButtons={() => (
          <>
            <Button type="submit" variant="contained">
              Confirm Account
            </Button>
            <Divider />
            <Button onClick={handleGenerateNewCode} variant="text">
              Did not received the code ? Ask a new one.
            </Button>
          </>
        )}
      />
    </Form>
  );
};

import { Amplify, Auth } from "aws-amplify";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { useNavigation } from "hooks/useNavigation";

Amplify.configure({
  Auth: {
    // REQUIRED: Amazon Cognito Identity Pool ID
    identityPoolId: process.env.REACT_APP_AWS_IDENTITY_ID,
    // REQUIRED: Amazon Cognito Region
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_AWS_USERPOOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_CLIENT_ID,
    identityPoolRegion: process.env.REACT_APP_AWS_REGION,
    oauth: {
      domain: process.env.REACT_APP_AWS_COGNITO_DOMAIN,
      scope: ["openid", "email", "profile"],
      redirectSignIn: `${process.env.REACT_APP_BASE_URL}/signin?ggsso=signin`,
      redirectSignOut: `${process.env.REACT_APP_BASE_URL}/signin?ggsso=signout`,
      responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },
});

export const useAuth = () => {
  const { goToHome } = useNavigation();

  const loginWithGoogleSSO = async () => {
    Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Google,
    });
    await getAuthUser(({ err }) => {
      if (!err) {
        goToHome();
      }
    });
  };

  const getAuthUser = async (cb?: (err?: any, data?: any) => void) => {
    try {
      const response = await Auth.currentAuthenticatedUser();
      if (cb) {
        cb({ data: response });
      }
    } catch (err) {
      if (cb) {
        cb({ err });
      }
    }
  };

  return {
    loginWithGoogleSSO,
    getAuthUser,
  };
};

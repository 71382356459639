import { ContentCopy } from "@mui/icons-material";
import { ButtonBase } from "@mui/material";
import { useNotifications } from "hooks/useNotifications";
import { useProfile, useRole } from "hooks/users";

const INVOICES_EMAIL = "invoices@app.invoicemagicbucket.com";

export const SubscriberEmailInfos = () => {
  const { data: userProfile } = useProfile();
  const { hasAccess } = useRole();
  const { pushNotification } = useNotifications();

  const handleCopyAlias = () => {
    navigator.clipboard.writeText(userProfile?.data?.emailAlias);
    pushNotification(
      "Your email alias has been copied to clipboard",
      "success"
    );
  };

  if (!hasAccess("BASIC")) {
    return <></>;
  }

  return (
    <>
      {userProfile?.data?.emailAlias && (
        <div className="flex-1">
          <p className="mb-2 text-lg font-medium">Custom Email</p>

          <p className="mb-2 text-sm italic text-gray-600">
            {
              "Send your PDF to this email and get them treated by our algorithm and save yourself time"
            }
          </p>

          <div className="flex flex-col gap-4">
            <div className="group relative rounded-lg bg-primary p-3 pr-8">
              <ButtonBase onClick={handleCopyAlias}>
                <div className="absolute right-2 top-1/2 hidden h-6 w-6 translate-y-[-50%] items-center justify-center rounded border-white bg-slate-600 p-2 text-sm text-gray-100 shadow-sm shadow-slate-500 group-hover:flex">
                  <ContentCopy fontSize="inherit" />
                </div>
              </ButtonBase>

              <a
                className="text-on-primary-colored"
                href={`mailto:${userProfile?.data?.emailAlias}`}
              >
                {userProfile?.data?.emailAlias}
              </a>
            </div>

            <p className="text-sm italic text-gray-600">
              {
                "or using our catch all email address (please make sure the email you send with is the same as the one you've register in our application)"
              }
            </p>

            <div className="group relative rounded-lg bg-primary p-3 pr-8">
              <ButtonBase onClick={handleCopyAlias}>
                <div className="absolute right-2 top-1/2 hidden h-6 w-6 translate-y-[-50%] items-center justify-center rounded border-white bg-slate-600 p-2 text-sm text-gray-100 shadow-sm shadow-slate-500 group-hover:flex">
                  <ContentCopy fontSize="inherit" />
                </div>
              </ButtonBase>

              <a
                className="text-on-primary-colored"
                href={`mailto:${INVOICES_EMAIL}`}
              >
                {INVOICES_EMAIL}
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

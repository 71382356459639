export const stripeStatusToText = (
  status: "void" | "draft" | "open" | "paid"
) => {
  switch (status) {
    case "void":
      return "Cancelled";
    case "paid":
      return "Paid";
    case "draft":
    case "open":
      return "New";
  }
};

export const sortByPrice = (productA: any, productB: any) => {
  const priceMonthA = productA.prices.find(
    (p: any) => p.recurringInterval === "month"
  );
  const priceMonthB = productB.prices.find(
    (p: any) => p.recurringInterval === "month"
  );

  return priceMonthA.amount - priceMonthB.amount;
};

import { useMutation, UseMutationOptions, useQueryClient } from "react-query";
import { useAuth } from "@ocf/react-auth";
import { updateUserProfile } from "api/user";

export interface UseUpdateProfileOptions {
  queryOptions?: UseMutationOptions<any, null, UseUpdateProfileForm>;
}

export interface UseUpdateProfileForm {
  firstname?: string;
  lastname?: string;
}

export const useUpdateProfile = (options?: UseUpdateProfileOptions) => {
  const { token } = useAuth();
  const client = useQueryClient();

  const queryOptions: UseMutationOptions<any, null, UseUpdateProfileForm> = {
    mutationKey: ["users", "subscription", "cancel"],
    mutationFn: (updateForm: UseUpdateProfileForm) =>
      updateUserProfile(token ?? "", {
        firstname: updateForm?.firstname,
        lastname: updateForm?.lastname,
      }),
    onSuccess: () => {
      client.invalidateQueries({
        queryKey: ["users"],
      });
    },
    ...options?.queryOptions,
  };

  return useMutation<any, null, UseUpdateProfileForm>(queryOptions);
};

import React from "react";
import { ButtonBase } from "@mui/material";
import { Bars } from "react-loader-spinner";

import { useNavigation } from "hooks/useNavigation";
import { useRole } from "hooks/users";
import moment from "moment";
import { Button } from "ui/molecules";

export interface InfosProps {
  label: string;
  amount: number;
  interval: "month" | "year";
  status: "active" | "canceled";
  canceled: boolean;
  canceledOn?: number;
  loading: boolean;
  onCancelSubscription: () => void;
  onResumeSubscription: () => void;
}

export const Infos: React.FC<InfosProps> = (props) => {
  const {
    label,
    amount,
    status,
    canceled,
    loading,
    canceledOn,
    onCancelSubscription,
    onResumeSubscription,
  } = props;
  const { role } = useRole();
  const { subscriptions } = useNavigation();

  return (
    <div className="h-fit w-2/3 rounded border p-4">
      <p className="text-center text-2xl font-semibold">{label}</p>

      <p className="mt-4 text-4xl">
        {amount} <span className="text-sm">{"€ / month"}</span>
      </p>

      <div className="mt-4 flex flex-row items-end justify-between">
        <div>
          <p>
            status:{" "}
            {canceled && canceledOn
              ? `Canceled (ends on ${moment(canceledOn * 1000).format(
                  "YYYY.MM.DD"
                )})`
              : status}
          </p>
        </div>
      </div>

      <div className="flex flex-row items-center gap-2">
        {status === "active" && !canceled ? (
          <div className="mt-4">
            <Button
              onClick={onCancelSubscription}
              label="Cancel Subscription"
            />
          </div>
        ) : (
          <div className="mt-4 flex flex-row items-center gap-4">
            {role !== "FREE" ? (
              <ButtonBase onClick={onResumeSubscription}>
                <div className="w-fit rounded bg-primary px-2 py-1 text-white hover:opacity-90">
                  {!loading ? (
                    <p>Resume Subscription</p>
                  ) : (
                    <Bars width={24} height={24} color="white" />
                  )}
                </div>
              </ButtonBase>
            ) : null}

            <ButtonBase onClick={subscriptions.goToPlans}>
              <p className="w-fit rounded bg-primary px-2 py-1 text-white hover:opacity-90">
                {role === "FREE"
                  ? "Choose new subscription"
                  : "Change Subscription"}
              </p>
            </ButtonBase>
          </div>
        )}
      </div>
    </div>
  );
};

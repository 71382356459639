import React from "react";

import { AppLayout } from "ui/layouts/app-layout/AppLayout";

import { TabsProvider } from "./fragments";
import { ProfileTabs } from "./tabs";
import { TabsContent } from "./tabs-content";

export interface ProfileProps {
  pane: "PROFILE" | "SUBSCRIPTION";
  subPage?: "PLANS" | "SUMMARY" | "PAYEMENT" | "DETAILS" | "SUCCESS";
}

export const Profile: React.FC<ProfileProps> = (props) => {
  const { pane, subPage } = props;

  return (
    <AppLayout>
      <TabsProvider fallback={pane}>
        <div className="flex h-full w-full flex-col gap-4 p-4">
          <ProfileTabs />

          <TabsContent
            componentProps={{
              subPage,
            }}
          />
        </div>
      </TabsProvider>
    </AppLayout>
  );
};

import axios from "axios";

import { handlePromise } from "utils/promises";
import { API_URLS } from "constants/api";
import { Filters } from "types/invoice";

export const getAllFilesData = async (token: string, filters?: Filters) => {
  const config = {
    headers: {
      Authorization: token,
    },
  };
  const { err, data } = await handlePromise(
    axios.get(API_URLS.getAllFiles(filters), config)
  );

  if (err || !data) {
    throw new Error("Error");
  }

  return data?.data;
};

export const getUserProfile = async (token: string) => {
  const config = {
    headers: {
      Authorization: token,
    },
  };

  const request = axios.get(API_URLS.userProfile(), config);
  const { err, data } = await handlePromise(request);

  if (err || !data) {
    throw new Error("Error");
  }

  return data?.data;
};

export const getSubscriptionsPlans = async (token: string) => {
  const config = {
    headers: {
      Authorization: token,
    },
  };

  const request = axios.get(API_URLS.subscriptionsPlans(), config);
  const { err, data } = await handlePromise(request);

  if (err || !data) {
    throw new Error("Error");
  }

  return data?.data;
};

export const createSubscriptionIntent = async (
  token: string,
  stripeCustomerId: string,
  stripePriceId: string,
  stripeCoupon: string | null
) => {
  const config = {
    headers: {
      Authorization: token,
    },
  };

  const formData = {
    customerId: stripeCustomerId,
    priceId: stripePriceId,
    coupon: stripeCoupon,
  };

  const request = axios.post(API_URLS.subscriptionsPlans(), formData, config);
  const { err, data } = await handlePromise(request);

  if (err || !data) {
    throw new Error("Error");
  }

  return data?.data;
};

interface CheckCouponValidityOptions {
  token: string;
  formData: {
    coupon: string;
  };
}

export const checkCouponValidity = async (
  options: CheckCouponValidityOptions
) => {
  const config = {
    headers: {
      Authorization: options.token,
    },
  };
  const request = axios.get(
    API_URLS.checkCouponValidity(options.formData.coupon),
    config
  );
  const { err, data } = await handlePromise(request);

  if (err || !data) {
    throw new Error("Error");
  }

  return data?.data;
};

export const getSubscriptionDetails = async (
  token: string,
  subscriptionId: string
) => {
  const config = {
    headers: {
      Authorization: token,
    },
  };

  const request = axios.get(
    API_URLS.subscriptionDetails(subscriptionId),
    config
  );
  const { err, data } = await handlePromise(request);

  if (err || !data) {
    throw new Error("Error");
  }

  return data?.data;
};

export const getPaymentHistory = async (token: string) => {
  const config = {
    headers: {
      Authorization: token,
    },
  };

  const request = axios.get(API_URLS.paymentHistory(), config);
  const { err, data } = await handlePromise(request);

  if (err || !data) {
    throw new Error("Error");
  }

  return data?.data;
};

export const cancelSubscription = async (token: string) => {
  const config = {
    headers: {
      Authorization: token,
    },
  };

  const request = axios.delete(API_URLS.subscriptionsPlans(), config);
  const { err, data } = await handlePromise(request);

  if (err || !data) {
    throw new Error("Error");
  }

  return data?.data;
};

export const resumeSubscription = async (token: string) => {
  const config = {
    headers: {
      Authorization: token,
    },
  };

  const request = axios.put(API_URLS.subscriptionsPlans(), null, config);
  const { err, data } = await handlePromise(request);

  if (err || !data) {
    throw new Error("Error");
  }

  return data?.data;
};

export const updateUserProfile = async (
  token: string,
  profileData: Partial<{ firstname: string; lastname: string }>
) => {
  const config = {
    headers: {
      Authorization: token,
    },
  };

  const request = axios.put(API_URLS.userProfile(), profileData, config);
  const { err, data } = await handlePromise(request);

  if (err || !data) {
    throw new Error("Error");
  }

  return data?.data;
};

import { useAuth } from "@ocf/react-auth";
import { useQuery } from "react-query";
import { QUERIES } from "constants/queries";

export const useGetInvoiceUrl = (fileId: string) => {
  const { token } = useAuth();
  const mutation = useQuery(QUERIES.getInvoiceUrl(token as string, fileId));

  return mutation;
};

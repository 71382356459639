import React from "react";
import classNames from "classnames";

import { ButtonBase } from "ui/atoms";
import { Button } from "ui/molecules";

export interface SubscriptionCardProps {
  stripeId: string;
  name: string;
  best?: boolean;
  monthlyPrice: {
    id: string;
    amount: number;
  };
  yearlyPrice: {
    id: string;
    amount: number;
  };
  onSelectPlan: (planId: string) => void;
}

export const SubscriptionCard: React.FC<SubscriptionCardProps> = (props) => {
  const { name, best, monthlyPrice, yearlyPrice, onSelectPlan } = props;
  const fileImg =
    name === "Basic Plan"
      ? "solo-file"
      : name === "Premium Plan"
      ? "lots-file-1"
      : "lots-file-2";

  return (
    <div className="mt-12 flex h-[550px] w-1/3">
      <div
        className={classNames(
          best ? "bg-[#173449] text-white" : "bg-white text-[#212121]",
          "w-full h-full flex flex-col p-4 rounded relative justify-between border-2 border-primary"
        )}
      >
        {best && (
          <div className="absolute left-4 top-0 translate-y-[-20%] rounded-full bg-[#F6C28B] px-4 py-1">
            <p className="text-sm font-semibold text-[#173449]">Best</p>
          </div>
        )}
        <div className="flex flex-col items-center">
          <img src={`/${fileImg}.svg`} className="h-16 w-16" />
          <p className="mt-4 text-center text-2xl font-medium">{name}</p>
        </div>

        <div className="my-8 text-center text-sm font-medium text-neutral-400">
          <p>Unlimited invoices upload</p>
          <p>Unlimited AI analysing</p>
          <p>Unlimited custom email sending</p>
          <p>Unlimited general email bucket</p>
          <p>Unlimited Downloads (PDF, CSV)</p>
          <p>Invoice label with categories</p>
          <p>Invoices advanced filters</p>
        </div>

        <div className="flex flex-col gap-4">
          <div className="flex flex-col items-center gap-2">
            <p className="text-4xl">
              {monthlyPrice.amount.toFixed(2)}
              <span className="text-lg">{"€ "}</span>
              <span className="text-sm">{"/ month"}</span>
            </p>

            <Button
              onClick={() => onSelectPlan(monthlyPrice.id)}
              label="Select Monthly"
            />
          </div>

          <div className="border-b border-solid border-neutral-100" />

          <div className="flex flex-col items-center gap-2">
            <p className="text-2xl">
              {yearlyPrice.amount.toFixed(2)}
              <span className="text-base">{"€ "}</span>
              <span className="text-sm">{"/ year"}</span>
            </p>

            <ButtonBase onClick={() => onSelectPlan(yearlyPrice.id)}>
              <p
                className={classNames(
                  best
                    ? "border-primary text-[#F6C28B] hover:bg-primary-darker hover:text-on-primary"
                    : "border-primary text-[#173449] hover:bg-primary-darker hover:text-on-primary",
                  "rounded-md border-2 border-solid px-4 py-2 text-sm"
                )}
              >
                Select Yearly
              </p>
            </ButtonBase>
          </div>
        </div>
      </div>
    </div>
  );
};

import React, { useState } from "react";
import {
  IconButton,
  Stack,
  Modal,
  Typography,
  Box,
  CircularProgress,
  Badge,
} from "@mui/material";
import { Edit, Delete, Visibility } from "@mui/icons-material";

import { FileDownloadButtons } from "../FileDownloadButtons";

import { useDeleteFiles } from "hooks/files/useDeleteFiles";

import {
  Container,
  MainActionsContainer,
  ModalContainer,
  Button,
} from "./styles";
import { FilterInvoicesButton } from "./FilterInvoicesButton";
import { useProfile, useRole } from "hooks/users";

export interface FilesGridActionBarProps {
  selectedToDownload: { id: string; filename: string }[];
  setSelectedToDownload: React.Dispatch<
    React.SetStateAction<{ id: string; filename: string }[]>
  >;
  selecting: boolean;
  setSelecting: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedToEdit: any;
  setVisualizerOpen: any;
  onOpenAddFiles: () => void;
}

export const FilesGridActionBar: React.FC<FilesGridActionBarProps> = (
  props
) => {
  const {
    selectedToDownload,
    setSelectedToDownload,
    selecting,
    setSelecting,
    setSelectedToEdit,
    setVisualizerOpen,
    onOpenAddFiles,
  } = props;
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const { mutate, isLoading } = useDeleteFiles({
    queryOptions: {
      onSuccess: () => {
        closeDeleteModal();
      },
    },
  });
  const { role } = useRole();
  const { data: userProfile } = useProfile();

  const btnBadgeContent =
    role === "FREE" ? `${userProfile?.data?.remainingTries} trials` : null;

  const toggleSelecting = () => {
    setSelecting((prevState: boolean) => {
      if (prevState) {
        setSelectedToDownload([]);
      }

      return !prevState;
    });
  };

  const openDeleteModal = () => {
    setDeleteConfirmOpen(true);
  };

  const closeDeleteModal = () => {
    setDeleteConfirmOpen(false);
  };

  const deleteFiles = () => {
    mutate({ fileIds: selectedToDownload.map((i) => i.id) });
  };

  return (
    <div className="flex flex-row items-center bg-white p-2 shadow">
      <MainActionsContainer alignItems="flex-start">
        <Button onClick={toggleSelecting}>
          {selecting ? "Cancel" : "Select"}
        </Button>
      </MainActionsContainer>

      <Stack direction="row">
        {selectedToDownload?.length === 1 && (
          <>
            <IconButton
              onClick={setVisualizerOpen}
              disabled={selectedToDownload?.length !== 1}
            >
              <Visibility />
            </IconButton>

            <IconButton
              onClick={setSelectedToEdit}
              disabled={selectedToDownload?.length !== 1}
            >
              <Edit />
            </IconButton>
          </>
        )}

        <FileDownloadButtons
          selectedToDownload={selectedToDownload.map((i) => i.id)}
        />

        <IconButton
          onClick={openDeleteModal}
          disabled={selectedToDownload?.length === 0}
        >
          <Delete
            color={selectedToDownload.length === 0 ? "inherit" : "error"}
          />
        </IconButton>

        <Modal open={deleteConfirmOpen} onClose={closeDeleteModal}>
          <ModalContainer>
            <Typography variant="h6">
              Confirm deletion of following file(s) ({selectedToDownload.length}
              )
            </Typography>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                maxHeight: "300px",
                overflow: "auto",
              }}
            >
              {selectedToDownload.map((i) => (
                <Typography
                  key={i.id}
                  sx={{ fontStyle: "italic" }}
                >{`${i.filename}.pdf`}</Typography>
              ))}
            </Box>

            <Box
              sx={{
                gap: "1rem",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Button onClick={closeDeleteModal} disabled={isLoading}>
                Cancel
              </Button>
              <Button
                color="error"
                variant="contained"
                onClick={deleteFiles}
                disabled={isLoading}
              >
                {isLoading ? <CircularProgress size={14} /> : "Delete"}
              </Button>
            </Box>
          </ModalContainer>
        </Modal>

        <FilterInvoicesButton />
        <Badge badgeContent={btnBadgeContent} variant="standard" color="error">
          <Button
            onClick={onOpenAddFiles}
            variant="contained"
            id="upload-btn"
            color="primary"
            size="small"
            sx={{ marginLeft: 1 }}
          >
            Upload Invoices
          </Button>
        </Badge>
      </Stack>
    </div>
  );
};

import { styled } from "@mui/material";

import { useNavigation } from "hooks/useNavigation";
import { Button } from "ui/molecules";

const Container = styled("div")`
  background-image: radial-gradient(
    circle farthest-corner at 10% 20%,
    rgba(176, 229, 208, 1) 42%,
    rgba(92, 202, 238, 0.41) 93.6%
  );
`;

export const PageNotFound = () => {
  const { goToHome } = useNavigation();

  return (
    <Container className="flex h-full w-full flex-col items-center justify-center">
      <div className="flex w-[400px] flex-col gap-4 rounded bg-white p-4 shadow">
        <p className="text-2xl font-semibold">Oups, wrong url</p>
        <p className="font-medium">
          {
            "It's seems that there's nothing here. Maybe check the url, if you came here by error, you can always head back to your dashboard using the button below."
          }
        </p>
        <Button label="Head back to dashboard" onClick={goToHome} />
      </div>
    </Container>
  );
};

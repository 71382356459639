import React from "react";

export interface ButtonBaseProps {
  onClick: React.MouseEventHandler<HTMLDivElement>;
  className?: string;
}

export const ButtonBase: React.FC<React.PropsWithChildren<ButtonBaseProps>> = (
  props
) => {
  const { onClick, children, className } = props;

  return (
    <div onClick={onClick} role="button" tabIndex={0} className={className}>
      {children}
    </div>
  );
};

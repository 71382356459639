import React from "react";
import classNames from "classnames";

import { ButtonBase } from "ui/atoms";

import { useTabs } from "./TabsContext";
import { useNavigation } from "hooks/useNavigation";

export interface TabProps {
  label: string;
  tab: string | number;
  url: string;
}

export const Tab: React.FC<TabProps> = (props) => {
  const { label, tab, url } = props;
  const { activeTab } = useTabs();
  const { goToCustom } = useNavigation();

  return (
    <ButtonBase
      onClick={() => goToCustom(url)}
      className="rounded px-2 py-1 hover:bg-neutral-100"
    >
      <p
        className={classNames(
          activeTab === tab ? "border-primary" : "border-transparent",
          "border-b-2 border-solid"
        )}
      >
        {label}
      </p>
    </ButtonBase>
  );
};

import { useQuery, UseQueryOptions } from "react-query";
import { useAuth } from "@ocf/react-auth";
import { getUserProfile } from "api/user";

export interface UseProfileOptions {
  queryOptions?: UseQueryOptions<any>;
}

export const useProfile = (options?: UseProfileOptions) => {
  const { token } = useAuth();

  const queryOptions: UseQueryOptions<any> = {
    queryKey: ["users", "profile"],
    queryFn: () => getUserProfile(token ?? ""),
    enabled: !!token,
    ...options?.queryOptions,
  };

  return useQuery(queryOptions);
};

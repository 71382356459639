import { useLocation, useNavigate as useRRDNavigation } from "react-router-dom";
import { utf8_to_b64 } from "utils/base64";

interface GoToSummaryOptions {
  planId: string;
}

export const useNavigation = () => {
  const location = useLocation();
  const navigate = useRRDNavigation();

  const goToSignin = () => {
    navigate("/signin");
  };

  const goToRegister = () => {
    navigate("/register");
  };

  const goToHome = () => {
    navigate("/dashboard");
  };

  const goToConfirmAccount = (email: string) => {
    navigate(`/register/confirm?email=${utf8_to_b64(email)}`);
  };

  const goToProfile = () => {
    navigate("/profile");
  };

  const goToForgotPassword = () => {
    navigate("/forgot-password");
  };

  const goToResetPassword = (state: Record<string, string>) => {
    navigate("/forgot-password/confirm", { state });
  };

  const goToPayement = (subscriptionId: string) => {
    navigate(`/subscription/payement?sub=${subscriptionId}`);
  };

  const goToPlans = () => {
    navigate("/subscription/plans");
  };

  const goToSummary = (options: GoToSummaryOptions) => {
    navigate(`/subscription/summary?plan=${options.planId}`);
  };

  const goToSuccess = () => {
    navigate("/subscription/success");
  };

  const goToDetails = () => {
    navigate("/subscription");
  };

  const goToCustom = (url: string) => {
    navigate(url);
  };

  const queries = new URLSearchParams(location.search);

  return {
    queries,
    goToHome,
    goToRegister,
    goToSignin,
    goToConfirmAccount,
    goToProfile,
    goToForgotPassword,
    goToResetPassword,
    subscriptions: {
      goToPayement,
      goToPlans,
      goToSuccess,
      goToDetails,
      goToSummary,
    },
    goToCustom,
  };
};

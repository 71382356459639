import { useMutation, UseMutationOptions, useQueryClient } from "react-query";
import { useAuth } from "@ocf/react-auth";
import { cancelSubscription } from "api/user";

export interface UseCancelSubscriptionOptions {
  queryOptions?: UseMutationOptions<any>;
}

export interface UseCancelSubscriptionForm {
  customerId: string;
  priceId: string;
}

export const useCancelSubscription = (
  options?: UseCancelSubscriptionOptions
) => {
  const { token } = useAuth();
  const client = useQueryClient();

  const queryOptions: UseMutationOptions<any, null> = {
    mutationKey: ["users", "subscription", "cancel"],
    mutationFn: () => cancelSubscription(token ?? ""),
    onSuccess: () => {
      client.invalidateQueries({
        queryKey: ["users"],
      });
    },
    ...options?.queryOptions,
  };

  return useMutation<any, null>(queryOptions);
};

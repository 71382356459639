import React, { createContext, useContext, useState } from "react";

interface SelectItem<T> {
  id: string;
  data: T;
}

interface FilesActionsValue {
  selected: SelectItem<any>[];
}

interface FilesActionsMethods {
  select: (id: string, data: any) => void;
  deselect: (id: string) => void;
}

type FilesActionsState = FilesActionsMethods & FilesActionsValue;

const defaultValue: FilesActionsValue = {
  selected: [],
};

const defaultMethods: FilesActionsMethods = {
  select: () => {
    //
  },
  deselect: () => {
    //
  },
};

const defaultState = { ...defaultMethods, ...defaultValue };

export const FilesActionsContext =
  createContext<FilesActionsState>(defaultState);

export const FilesActionsProvider: React.FC<React.PropsWithChildren> = (
  props
) => {
  const { children } = props;
  const [state, setState] = useState(defaultValue);

  const select = (id: string, data: any) => {
    setState((prevState) => ({
      ...prevState,
      selected: [...prevState.selected, { id, data }],
    }));
  };

  const deselect = (id: string) => {
    setState((prevState) => ({
      ...prevState,
      selected: [...prevState.selected.filter((item) => item.id === id)],
    }));
  };

  const value = {
    ...state,
    select,
    deselect,
  };

  return (
    <FilesActionsContext.Provider value={value}>
      {children}
    </FilesActionsContext.Provider>
  );
};

export const useFilesActions = () => {
  return useContext(FilesActionsContext);
};

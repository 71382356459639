import * as yup from "yup";

export const passwordSchema = yup
  .string()
  .min(8)
  .matches(/[0-9]/, "password should contains a number")
  .matches(/[a-z]/, "password should contains lowercase character")
  .matches(/[A-Z]/, "password should contains uppercase character")
  .matches(
    /[\^$*.[\]{}()?\-"!@#%&/\\,><':;|_~`=]/,
    "password should contain a special character ^$*.[](){}?-\"!@#%&/\\,><':;|_~`+="
  )
  .required();

export const emailSchema = yup.string().email().required();

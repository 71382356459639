import { Filters } from "types/invoice";

const buildPath = (
  pathname: string,
  queryParams?: any,
  isUser?: boolean
): string => {
  const params = queryParams
    ? `?${new URLSearchParams(queryParams).toString()}`
    : "";
  // In staging and prod, APIs URL should be: https://[CLOUDFRONT_DOMAIN_NAME]/[API_NAME]
  // const url = `${
  //   isUser
  //     ? process.env.REACT_APP_USERS_API_BASE_URL
  //     : process.env.REACT_APP_API_BASE_URL
  // }${
  //   process.env.REACT_APP_API_STAGE ? `/${process.env.REACT_APP_API_STAGE}` : ""
  // }${pathname}${params}`;
  const url = `${process.env.REACT_APP_API_BASE_URL}${pathname}${params}`;
  return url;
};

const buildFiltersQueryParams = (filters?: Filters) => {
  const filtersParams: any = {};

  if (!filters) {
    return null;
  }

  if (filters?.date) {
    if (filters.date?.start) {
      filtersParams.ds = filters.date.start;
    }

    if (filters.date?.end) {
      filtersParams.de = filters.date.end;
    }
  }

  filtersParams.d = "i";

  return filtersParams;
};

type Type = "PDF" | "CSV";

export const API_URLS = {
  uploadFile: () => buildPath("/invoice-api/uploads"),
  getAllFiles: (filters?: Filters) =>
    buildPath("/invoice-api/invoices", buildFiltersQueryParams(filters)),
  downloadFiles: (type: Type) =>
    buildPath("/invoice-api/invoices/download", { type }),
  deleteFiles: () => buildPath("/invoice-api/invoices"),
  // update to invoiceAttribute
  getInvoiceUrl: (fileId: string) =>
    buildPath(`/invoice-api/invoices/${fileId}/url`),
  invoiceUpdate: (fileId: string) =>
    buildPath(`/invoice-api/invoices/${fileId}`),
  userProfile: () => buildPath("/users-api/me", null, true),
  subscriptionsPlans: () => buildPath(`/users-api/subscriptions`, null, true),
  checkCouponValidity: (coupon: string) =>
    buildPath(`/users-api/subscriptions/coupons/${coupon}`, null, true),
  subscriptionDetails: (subscriptionId: string) =>
    buildPath(`/users-api/subscriptions/${subscriptionId}`, null, true),
  paymentHistory: () =>
    buildPath(`/users-api/subscriptions/payments`, null, true),
};

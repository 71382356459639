import * as yup from "yup";
import { passwordSchema } from "utils/form-schemas";

export interface ResetPasswordForm {
  password: string;
  confirmPassword: string;
  code: string;
}

export const resetPassswordSchema = yup.object({
  password: passwordSchema,
  confirmPassword: passwordSchema.oneOf(
    [yup.ref("password")],
    "Password must match"
  ),
  code: yup.string().length(6).required(),
});

export const defaultValues: ResetPasswordForm = {
  password: "",
  confirmPassword: "",
  code: "",
};

import React from "react";
import { useAuth } from "@ocf/react-auth";
import { Navigate } from "react-router-dom";

export const Public: React.FC<React.PropsWithChildren> = (props) => {
  const { children } = props;
  const { loading, token } = useAuth();
  // TODO: regler ce soucis de loading

  if (loading && token) {
    return <p>Loading</p>;
  } else if (token) {
    return <Navigate to="/" />;
  }

  return <>{children}</>;
};

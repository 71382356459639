import { ProfilePanel, SubscriptionPanel } from "./fragments";

export const TABS = [
  {
    key: "PROFILE",
    label: "Profile",
    url: "/profile",
    Component: ProfilePanel,
  },
  {
    key: "SUBSCRIPTION",
    label: "Subscription",
    url: "/subscription",
    Component: SubscriptionPanel,
  },
];

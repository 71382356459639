import { useProfile, useSubscriptionDetails } from "hooks/users";

export const useRole = () => {
  const { data: profile, isLoading: profileLoading } = useProfile();
  const { data: subDetails, isLoading: subLoading } = useSubscriptionDetails({
    subscriptionId: profile?.data?.stripeSubscriptionId,
  });

  const hasAccess = (
    role: "FREE" | "BASIC" | "PREMIUM" | "BELIEVER",
    strict?: boolean
  ) => {
    if (role === "FREE") {
      return true;
    }

    if (profileLoading || subLoading) {
      return false;
    }

    if (
      (profile?.data?.remainingTries ?? 0) > 0 &&
      !profile?.data?.stripeSubscriptionId
    ) {
      return true;
    }

    if (
      subDetails?.data?.status !== "active" ||
      (subDetails?.data?.status === "canceled" &&
        (subDetails?.data?.currentPeriodEnd ?? 0) <
          new Date(Date.now()).getTime())
    ) {
      return false;
    }

    if (profile?.data?.role) {
      if (strict) {
        return role === profile.data.role;
      }

      if (role === "BELIEVER" && profile.data.role !== "BELIEVER") {
        return false;
      }

      if (
        role === "PREMIUM" &&
        !["PREMIUM", "BELIEVER"].includes(profile.data.role)
      ) {
        return false;
      }

      if (
        role === "BASIC" &&
        !["BASIC", "PREMIUM", "BELIEVER"].includes(profile.data.role)
      ) {
        return false;
      }

      return true;
    }

    return false;
  };

  return {
    hasAccess,
    role: profile?.data?.role,
  };
};

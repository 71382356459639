import React, { useState } from "react";
import {
  Chip,
  Popover,
  PopoverProps,
  Button,
  Divider,
  TextField,
} from "@mui/material";

import { Filters } from "types/invoice";

import { useInvoices } from "../../../providers/InvoicesProvider";
import {
  dateToTimestamp,
  getDateInterval,
  mapChipIdToInterval,
} from "./FiltersPopover.utils";
import { ChipId } from "./FiltersPopover.types";
import {
  Container,
  LeftContainer,
  ConfirmContainer,
  RightContainer,
  InputsContainer,
  ChipsContent,
  ChipRowContainer,
} from "./styles";

export interface FiltersPopoverProps {
  open: boolean;
  onClose: () => void;
  anchorEl: PopoverProps["anchorEl"];
}

export const FiltersPopover: React.FC<FiltersPopoverProps> = (props) => {
  const { open, onClose, anchorEl } = props;
  const [filters, setFilters] = useState<Filters>({});
  const [selectedDateChip, setSelectedDateChip] = useState<ChipId | null>(null);
  const { applyFilters } = useInvoices();

  const handleDateChange = (kind: "start" | "end") => (ev: any) => {
    setFilters((prevState) => ({
      ...prevState,
      date: {
        ...(prevState?.date ?? {}),
        [kind]: ev.target.value,
      },
    }));
  };

  const handleChipDateSelect = (chipId: ChipId) => () => {
    const [intervalName, factor] = mapChipIdToInterval(chipId);

    if (intervalName && factor !== null) {
      const interval = getDateInterval(intervalName, factor);
      setFilters((prevState) => ({
        ...prevState,
        date: interval,
      }));
      setSelectedDateChip(chipId);
    }
  };

  const handleApplyFilters = () => {
    applyFilters({
      ...filters,
      ...(filters?.date
        ? {
            date: {
              ...(filters.date?.start
                ? { start: dateToTimestamp(filters.date.start?.toString()) }
                : {}),
              ...(filters.date?.end
                ? { end: dateToTimestamp(filters.date.end?.toString(), true) }
                : {}),
            },
          }
        : {}),
    });
  };

  const handleClearFilters = () => {
    setFilters({});
    setSelectedDateChip(null);
    applyFilters({});
  };

  const lastChips: { id: ChipId; label: string }[] = [
    { id: "s-1", label: "Last week" },
    { id: "m-1", label: "Last month" },
    { id: "a-1", label: "Last year" },
  ];

  const currentChips: { id: ChipId; label: string }[] = [
    { id: "s", label: "This week" },
    { id: "m", label: "This month" },
    { id: "a", label: "This year" },
  ];

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Container>
        <LeftContainer>
          <Button>Date</Button>

          <ConfirmContainer>
            <Button onClick={handleApplyFilters} variant="contained" fullWidth>
              Apply
            </Button>
            <Button onClick={handleClearFilters} fullWidth>
              Clear
            </Button>
          </ConfirmContainer>
        </LeftContainer>

        <Divider orientation="vertical" flexItem />

        <RightContainer>
          <InputsContainer>
            <TextField
              type="date"
              label="Start"
              value={filters?.date?.start ?? ""}
              onChange={handleDateChange("start")}
              InputLabelProps={{ shrink: true }}
              InputProps={
                filters?.date?.end
                  ? { inputProps: { max: filters.date.end?.toString() } }
                  : undefined
              }
            />
            <TextField
              type="date"
              label="End"
              value={filters?.date?.end ?? ""}
              onChange={handleDateChange("end")}
              InputLabelProps={{ shrink: true }}
              InputProps={
                filters?.date?.start
                  ? { inputProps: { min: filters.date.start?.toString() } }
                  : undefined
              }
            />
          </InputsContainer>

          <ChipsContent>
            <ChipRowContainer>
              {lastChips.map((chip) => (
                <Chip
                  key={chip.id}
                  label={chip.label}
                  onClick={handleChipDateSelect(chip.id)}
                  color={selectedDateChip === chip.id ? "primary" : "default"}
                />
              ))}
            </ChipRowContainer>
            <ChipRowContainer>
              {currentChips.map((chip) => (
                <Chip
                  key={chip.id}
                  label={chip.label}
                  onClick={handleChipDateSelect(chip.id)}
                  color={selectedDateChip === chip.id ? "primary" : "default"}
                />
              ))}
            </ChipRowContainer>
          </ChipsContent>
        </RightContainer>
      </Container>
    </Popover>
  );
};

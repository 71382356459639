import { createBrowserRouter, Navigate } from "react-router-dom";

import { Protected } from "./ProtectedRoute";
import { Public } from "./PublicRoute";

import { Home } from "features/home";
import { Profile } from "features/profile";
import { Signin } from "features/auth/signin";
import { ConfirmAccount } from "features/auth/confirm-account";
import { Register } from "features/auth/register";
import { ForgotPassword } from "features/auth/forgot-password";
import { ResetPassword } from "features/auth/reset-password";
import { PageNotFound } from "features/page-not-found";

export const router = createBrowserRouter([
  {
    path: "profile",
    element: (
      <Protected>
        <Profile pane="PROFILE" />
      </Protected>
    ),
    errorElement: <div>404 Page not found</div>,
  },
  {
    path: "signin",
    element: (
      <Public>
        <Signin />
      </Public>
    ),
  },
  {
    path: "forgot-password",
    children: [
      {
        index: true,
        element: (
          <Public>
            <ForgotPassword />
          </Public>
        ),
      },
      {
        path: "confirm",
        element: (
          <Public>
            <ResetPassword />
          </Public>
        ),
      },
    ],
  },
  {
    path: "subscription",
    children: [
      {
        index: true,
        element: (
          <Protected>
            <Profile pane="SUBSCRIPTION" subPage="DETAILS" />
          </Protected>
        ),
      },
      {
        path: "plans",
        element: (
          <Protected>
            <Profile pane="SUBSCRIPTION" subPage="PLANS" />
          </Protected>
        ),
      },
      {
        path: "summary",
        element: (
          <Protected>
            <Profile pane="SUBSCRIPTION" subPage="SUMMARY" />
          </Protected>
        ),
      },
      {
        path: "success",
        element: (
          <Protected>
            <Profile pane="SUBSCRIPTION" subPage="SUCCESS" />
          </Protected>
        ),
      },
      {
        path: "payement",
        element: (
          <Protected>
            <Profile pane="SUBSCRIPTION" subPage="PAYEMENT" />
          </Protected>
        ),
      },
    ],
  },
  {
    path: "register",
    children: [
      {
        index: true,
        element: (
          <Public>
            <Register />
          </Public>
        ),
      },
      {
        path: "confirm",
        element: (
          <Public>
            <ConfirmAccount />
          </Public>
        ),
      },
    ],
  },
  {
    path: "/",
    element: <Navigate to="/dashboard" />,
  },
  {
    path: "dashboard",
    element: (
      <Protected>
        <Home />
      </Protected>
    ),
  },
  {
    path: "*",
    element: <PageNotFound />,
  },
]);

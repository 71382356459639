import React from "react";
import { Controller } from "react-hook-form";
import { TextField, Typography, TextFieldProps, Box } from "@mui/material";

import { Container } from "./styled";

interface Field {
  label: string;
  key: string;
  name: string;
  textFieldProps?: TextFieldProps;
}

export interface AuthLayoutProps {
  control: any;
  renderButtons: () => JSX.Element;
  fields: Field[];
  title?: string;
  subtitle?: string;
  error?: string | null;
  sectionTitle?: string;
}

export const AuthLayout: React.FC<AuthLayoutProps> = (props) => {
  const {
    control,
    renderButtons,
    sectionTitle,
    fields,
    title,
    subtitle,
    error,
  } = props;

  return (
    <Container>
      <div className="flex w-[60%] max-w-[500px] flex-col gap-4 rounded bg-white p-8 shadow-xl">
        <Box sx={{ marginBottom: "1rem" }}>
          {title && (
            <h4 className="bg-gradient-to-tr from-[#b0e5d0] to-[#5ccaee] bg-clip-text text-center text-4xl text-transparent">
              {title}
            </h4>
          )}
          {subtitle && (
            <h6 className="text-center text-lg text-neutral-500">{subtitle}</h6>
          )}
        </Box>
        <Box>
          <h5 className="text-2xl">{sectionTitle}</h5>
        </Box>
        {error && (
          <Typography variant="subtitle1" color="error">
            {error}
          </Typography>
        )}
        {fields.map((inputField: Field) => (
          <Controller
            key={inputField.key}
            control={control}
            name={inputField.name}
            render={({ field }) => (
              <TextField
                label={inputField.label}
                value={field.value}
                onChange={field.onChange}
                {...(inputField?.textFieldProps || {})}
              />
            )}
          />
        ))}

        {renderButtons()}
      </div>
    </Container>
  );
};

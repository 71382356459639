import React from "react";
import { Paper, Stack, IconButton, styled } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useGetInvoiceUrl } from "hooks/files/useGetInvoiceUrl";
import { FileViewer } from "ui/atoms";

import { Container } from "./styles";

const PaperContainer = styled(Paper)`
  padding: 8px;
`;

interface FileVisualizer {
  fileId: string;
  onClose: () => void;
}

// TODO: improvment => add onLoadSuccess and onLoadError on Document
// to add feedback to user
export const FileVisualizer: React.FC<FileVisualizer> = React.memo(
  function FileVisualizer({ fileId, onClose }: FileVisualizer) {
    const { data } = useGetInvoiceUrl(fileId);

    return (
      <Container>
        <PaperContainer>
          <Stack direction="row" justifyContent="flex-end">
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Stack>
        </PaperContainer>

        <div>{data && <FileViewer url={data} />}</div>
      </Container>
    );
  },
  arePropsEqual
);

function arePropsEqual(oldProps: FileVisualizer, newProps: FileVisualizer) {
  return oldProps.fileId === newProps.fileId;
}

import { useState } from "react";
import Joyride, { Step } from "react-joyride";

import { useRole } from "hooks/users";

import { AppLayout } from "ui/layouts/app-layout/AppLayout";

import { FilesGridContainer } from "./styled";
import { InvoicesProvider } from "./providers/InvoicesProvider";
import { UploadModal, FilesGrid, FilesActionsProvider } from "./fragments";

const onboardingSteps: Step[] = [
  {
    target: "body",
    title: "Welcome to IMB",
    content: "Let's take a small tour",
    placement: "center",
  },
  {
    target: "#grid",
    title: "The Grid",
    content: "This is where all your invoices will be",
    placement: "center",
  },
  {
    target: "#upload-btn",
    title: "Upload",
    content:
      "This button allows you to upload your invoices, and let our AI analyse them and give you structured vision over your expenses",
  },
  {
    target: "#menu-profile-btn",
    title: "Profile",
    content:
      "This is the profile, and the place you can go to subscribe and start using our features",
  },
];

const needSubscriptionSteps: Step[] = [
  {
    target: "#menu-profile-btn",
    title: "Profile",
    content:
      "To be able to use our features, head to the profile and choose a subscription plan",
  },
];

export const Home = () => {
  const [open, setOpen] = useState(false);
  const [run, setRun] = useState(
    !!localStorage.getItem("onboarding") || !!localStorage.getItem("subwarn")
  );
  const [steps, setSteps] = useState(
    localStorage.getItem("onboarding") === "true"
      ? onboardingSteps
      : localStorage.getItem("subwarn") === "true"
      ? needSubscriptionSteps
      : []
  );
  const { hasAccess } = useRole();

  const openModel = () => {
    if (hasAccess("BASIC")) {
      setOpen(true);
    } else {
      localStorage.setItem("subwarn", "true");
      setSteps(needSubscriptionSteps);
      setRun(true);
    }
  };

  const closeModal = () => {
    setOpen(false);
  };

  const callback = (data: any) => {
    if (data.action === "reset") {
      setSteps([]);
      setRun(false);
      localStorage.removeItem("onboarding");
      localStorage.removeItem("subwarn");
    }
  };

  return (
    <InvoicesProvider>
      <FilesActionsProvider>
        <AppLayout>
          <FilesGridContainer>
            <FilesGrid onOpenAddFiles={openModel} />
          </FilesGridContainer>

          <UploadModal open={open} onClose={closeModal} />

          <Joyride
            styles={{
              options: {
                zIndex: 999,
              },
            }}
            continuous
            hideCloseButton
            callback={callback}
            scrollToFirstStep
            showProgress
            showSkipButton
            steps={steps}
            run={run}
          />
        </AppLayout>
      </FilesActionsProvider>
    </InvoicesProvider>
  );
};

import * as yup from "yup";
import { emailSchema } from "utils/form-schemas";

export interface ForgotPasswordForm {
  email: string;
}

export const forgotPasswordSchema = yup.object({
  email: emailSchema,
});

export const defaultValues: ForgotPasswordForm = {
  email: "",
};

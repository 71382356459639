import React from "react";
import {
  SubscriptionDetails,
  SubscriptionPayment,
  SubscriptionPlans,
  SubscriptionSuccess,
  SubscriptionSummary,
} from "../subscription";

export interface SubscriptionPanelOldProps {
  defaultStep?: number;
}

export interface SubscriptionPanelProps {
  subPage: "PLANS" | "SUMMARY" | "PAYEMENT" | "DETAILS" | "SUCCESS";
}

const Subscription = {
  Details: SubscriptionDetails,
  Plans: SubscriptionPlans,
  Summary: SubscriptionSummary,
  Success: SubscriptionSuccess,
  Payement: SubscriptionPayment,
};

const getSubPageComponent = (subPage: SubscriptionPanelProps["subPage"]) => {
  switch (subPage) {
    case "DETAILS":
      return Subscription.Details;
    case "PLANS":
      return Subscription.Plans;
    case "SUMMARY":
      return Subscription.Summary;
    case "SUCCESS":
      return Subscription.Success;
    case "PAYEMENT":
      return Subscription.Payement;
  }
};

export const SubscriptionPanel: React.FC<SubscriptionPanelProps> = (props) => {
  const { subPage } = props;

  const SubPageComponent = getSubPageComponent(subPage);

  return (
    <>
      <SubPageComponent />
    </>
  );
};

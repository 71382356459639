import { useEffect, useState } from "react";

import { useNavigation } from "hooks/useNavigation";
import { Button } from "ui/molecules";
import {
  useCreateSubscription,
  useProfile,
  useSubscriptionCoupon,
  useSubscriptionDetails,
  useSubscriptionsPlans,
} from "hooks/users";
import { handlePromise } from "utils/promises";
import { Bars } from "react-loader-spinner";
import classNames from "classnames";
import { ButtonBase } from "ui/atoms";

export interface Coupon {
  isValid: boolean;
  percentageOff?: number;
  amountOff?: number;
  duration?: number;
}

export const getReducedPrice = (coupon: Coupon, price: number) => {
  if (coupon.amountOff) {
    return ((price - coupon.amountOff) / 100).toFixed(2);
  }

  if (coupon.percentageOff) {
    const amount = price * ((100 - coupon?.percentageOff) / 100);
    return (amount / 100).toFixed(2);
  }

  return (price / 100).toFixed(2);
};

export const getCouponAppliedAmount = (coupon: Coupon, price: number) => {
  if (coupon?.isValid) {
    if (coupon.duration === -1) {
      return {
        strikeText: `${(price / 100).toFixed(2)} €`,
        text: `${getReducedPrice(coupon, price)} € (-${
          coupon?.percentageOff
            ? coupon?.percentageOff
            : ((coupon?.amountOff ?? 1) / 100).toFixed(2)
        }${coupon.percentageOff ? "%" : "€"})`,
      };
    } else if (coupon.duration === 1) {
      return {
        strikeText: `${(price / 100).toFixed(2)} €`,
        text: `${getReducedPrice(coupon, price)} € (-${
          coupon?.percentageOff
            ? coupon?.percentageOff
            : ((coupon?.amountOff ?? 1) / 100).toFixed(2)
        }${coupon.percentageOff ? "%" : "€"})`,
        subText: `Once, then ${(price / 100).toFixed(2)}€`,
      };
    } else if (Number.isInteger(coupon.duration)) {
      return {
        strikeText: `${(price / 100).toFixed(2)} €`,
        text: `${getReducedPrice(coupon, price)} € (-${
          coupon?.percentageOff
            ? coupon?.percentageOff
            : ((coupon?.amountOff ?? 1) / 100).toFixed(2)
        }${coupon.percentageOff ? "%" : "€"})`,
        subText: `For ${coupon.duration} months, then ${(price / 100).toFixed(
          2
        )}€`,
      };
    }
  }

  return {
    text: `${(price / 100).toFixed(2)} €`,
  };
};

export const SubscriptionSummary = () => {
  const [checkCoupon, setCheckCoupon] = useState<null | string>(null);
  const [coupon, setCoupon] = useState("");
  const { queries } = useNavigation();
  const { data: userProfile, isLoading: userLoading } = useProfile();
  const { data: subscriptionDetails, isLoading: subDetailsLoading } =
    useSubscriptionDetails({
      subscriptionId: userProfile?.data?.stripeSubscriptionId,
    });
  const { data: subscriptionsPlans, isLoading: plansLoading } =
    useSubscriptionsPlans();
  const { mutateAsync: createSubscription, isLoading: createLoading } =
    useCreateSubscription();
  const { subscriptions } = useNavigation();
  const { data: couponValidity, isLoading: isValidityLoading } =
    useSubscriptionCoupon({
      coupon: checkCoupon,
    });
  const couponInvalid =
    checkCoupon &&
    coupon &&
    !couponValidity?.data?.isValid &&
    !isValidityLoading;
  const priceId = queries.get("plan");
  const subscription = subscriptionsPlans?.data?.find((sub: any) => {
    if (sub?.prices?.find((p: any) => p.id === priceId)) {
      return true;
    }

    return false;
  });
  const plan = subscription?.prices?.find((p: any) => p.id === priceId);

  useEffect(() => {
    if (
      !priceId ||
      (subscriptionDetails?.data?.status === "active" &&
        !subscriptionDetails?.data?.canceled)
    ) {
      subscriptions.goToDetails();
    }
  }, [priceId, subscriptionDetails]);

  // TODO: if i have an active subscription then redirect to details to avoid subscribing twice and add protection to backend

  const handleApplyCoupon = () => {
    setCheckCoupon(coupon);
  };

  const onCancel = () => {
    subscriptions.goToDetails();
  };

  const onConfirm = async () => {
    if (couponInvalid) {
      return;
    }

    if (!priceId) {
      return;
    }

    const request = createSubscription({
      priceId,
      customerId: userProfile?.data?.stripeCustomerId,
      coupon,
    });
    const { err, data } = await handlePromise(request);

    if (err) {
      return;
    }

    if (data) {
      localStorage.setItem("clientSecret", data.data.clientSecret);
      subscriptions.goToPayement(data.data.stripeSubscriptionId);
    }
  };

  if (subDetailsLoading || userLoading || plansLoading) {
    return (
      <div className="flex w-full justify-center pt-12">
        <Bars width={24} height={24} color="#11998e" />
      </div>
    );
  }

  const subPricing: {
    strikeText?: string;
    text?: string;
    subText?: string;
  } =
    couponValidity?.data || plan?.amount
      ? getCouponAppliedAmount(couponValidity?.data, plan?.amount * 100)
      : {};

  return (
    <div className="flex w-full justify-center pt-12">
      <div className="flex w-[450px] flex-col gap-4 rounded bg-[#C3E5E3] p-4">
        <p className="text-xl font-semibold">Subscription</p>

        <div className="flex flex-col gap-2 rounded border border-neutral-500 p-2">
          <div className="flex w-full flex-row items-center justify-between gap-4">
            <p className="text-neutral-500">Selected Plan</p>
            <p className="text-lg">{subscription?.name}</p>
          </div>

          <div className="flex w-full flex-row items-center justify-between gap-4">
            <p className="text-neutral-500">Recurrence</p>
            <p className="text-lg">
              {plan?.recurringInterval === "month" ? "Monthly" : "Yearly"}
            </p>
          </div>

          <div className="flex w-full flex-row items-center justify-between gap-4">
            <p className="text-neutral-500">Amount</p>
            <div className="flex flex-col gap-1">
              <p className="text-lg">
                {subPricing?.strikeText && (
                  <span className="mr-2 text-base text-neutral-600 line-through">
                    {subPricing.strikeText}
                  </span>
                )}
                {subPricing?.text && (
                  <span
                    className={classNames(
                      subPricing?.strikeText ? "font-medium text-red-600" : ""
                    )}
                  >
                    {subPricing.text}
                  </span>
                )}
              </p>

              {subPricing?.subText && (
                <p className="text-xs font-medium text-neutral-500">
                  {subPricing.subText}
                </p>
              )}
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-2 rounded border border-neutral-500 p-2">
          <label htmlFor="coupon">Coupon</label>

          {couponInvalid && (
            <p className="text-sm text-red-500">This coupon is not valid</p>
          )}
          {coupon === checkCoupon && couponValidity?.data?.isValid && (
            <p className="text-sm text-green-600">Coupon is valid</p>
          )}
          <div className="flex flex-row items-center gap-2">
            <input
              id="coupon"
              value={coupon}
              onChange={(ev) => setCoupon(ev.target.value)}
              className="flex w-full rounded bg-white px-4 py-2"
            />

            <ButtonBase onClick={handleApplyCoupon}>
              <p className="w-fit px-4 py-2 font-medium text-primary">Apply</p>
            </ButtonBase>
          </div>
        </div>

        <div className="flex flex-row items-center justify-end gap-2">
          <ButtonBase onClick={onCancel}>
            <p className="w-fit px-4 py-2 font-medium text-primary">Cancel</p>
          </ButtonBase>
          <ButtonBase onClick={onConfirm}>
            {createLoading ? (
              <Bars width={18} height={18} color="#11998e" />
            ) : (
              <p className="w-fit rounded bg-primary px-4 py-2 text-on-primary shadow-sm">
                Confirm
              </p>
            )}
          </ButtonBase>
        </div>
      </div>
    </div>
  );
};
